<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dense dark color="light-blue darken-4">
                <v-btn icon dark @click="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>UML 다이어그램 (실험적 단계)</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-toolbar-items>
                    <v-btn dark text @click="cancel">닫기</v-btn>
                </v-toolbar-items> -->
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <div v-dragscroll class="imgScrollBox" style="margin-top: 24px">
                                <img :src="umlImage" />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as uml from "../api/uml";
import { errorBox, messageBox } from "../utils/toast";
import { dragscroll } from 'vue-dragscroll';

export default {
    directives: {
        dragscroll
    },

    data() {
        return {
            /**
             * 다이얼로그 제어 변수
             */
            dialog: false,

            umlImage: "",
        };
    },

    methods: {
        /**
         * 지정된 메소드의 시퀀스 다이어그램을 생성한다.
         */
        showSequence(classMethod) {
            uml.loadSequenceDiagram(classMethod).then((response) => {
                if (response.data.returnCode == true) {
                    if(response.data.result.convertMessage == 'SUCCESS') {
                        this.umlImage = response.data.result.umlImage;
                        this.dialog = true;
                    }
                    else {
                        this.umlImage = '';
                        messageBox(response.data.result.convertMessage);
                    }
                    
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch((err) => {
                errorBox(err);
                this.loading = false;
            });
        },

        /**
         * 창 열기
         */
        showClass(className) {
            uml.loadClassDiagram(className).then((response) => {
                if (response.data.returnCode == true) {
                    if(response.data.result.convertMessage == 'SUCCESS') {
                        this.umlImage = response.data.result.umlImage;
                        this.dialog = true;
                    }
                    else {
                        this.umlImage = '';
                        messageBox(response.data.result.convertMessage);
                    }
                    
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch((err) => {
                errorBox(err);
                this.loading = false;
            });
        },

        /**
         * 창 닫기
         */
        cancel() {
            this.$nextTick(() => {
                this.dialog = false;
            });
        },

        
    },
};
</script>
<style scoped>
.imgScrollBox {
    overflow:scroll;
    width:100%;
    height:100%;
    cursor: pointer;
    border: 1px solid #ebeaea;
}
</style>