<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <v-card class="mx-auto pa-0">
                    <v-toolbar color="#1E88E5" dark dense>
                        <v-toolbar-title>RDF 클래스</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn small color="#42A5F5" @click="addRdfClass">
                            클래스 추가
                        </v-btn>
                    </v-toolbar>
                </v-card>
                <v-data-table
                    :headers="headers"
                    :items="rdfClassList"
                    sort-by="calories"
                    class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="confirmDelete(item)">
                            mdi-delete
                        </v-icon>
                        <v-icon small @click="editRdfClass(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <!-- S:클래스 추가 -->
        <v-dialog
            v-model="dialogRdfClass"
            fullscreen
            persistent
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogRdfClass = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="saveRdfClass"> 저장 </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <ClassDefinition
                    ref="classDefine"
                    :targetObject="targetObject"
                />
            </v-card>
        </v-dialog>
        <!-- E:클래스 추가 -->

        <!-- S:삭제 확인 -->
        <v-dialog v-model="dialogDelete" max-width="350px">
            <v-card>
                <v-card-title class="text-h6"
                    >선택된 클래스를 삭제할까요?</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancelDelete"
                        >취소</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteRdfClass"
                        >확인</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- E:삭제 확인 -->
    </v-container>
</template>

<script>
// import { isEmpty } from "../utils/utils";
import * as rest from "../api/model";
import ClassDefinition from "../components/ClassDefinition.vue";
import { errorBox, messageBox } from "../utils/toast";
/**
 * ICON:
 * https://pictogrammers.github.io/@mdi/font/2.0.46/
 */
export default {
    data: () => ({
        /**
         * 편집대상 클래스
         */
        targetObject: null,
        isNew: true,

        /**
         * 클래스 추가 다이얼로그
         */
        dialogRdfClass: false,

        /**
         * 삭제 다이얼로그
         */
        dialogDelete: false,

        dialogAdd: false,

        headers: [
            {
                text: "클래스 이름",
                align: "start",
                sortable: false,
                value: "rdfClassName",
            },
            {
                text: "클래스 ID",
                value: "rdfClassId",
                sortable: false,
            },
            {
                text: "속성 개수",
                value: "propCount",
                sortable: false,
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                width: "10%",
            },
        ],

        /**
         * RDF 클래스 목록을 저장
         */
        rdfClassList: [],

        editedItem: {
            termName: "",
            variableId: "",
            dataType: "",
        },
    }),

    mounted() {
        this.loadClasses();
    },

    computed: {
        dialogTitle() {
            return this.isNew === true ? "RDF 클래스 추가" : "RDF 클래스 수정";
        },
    },

    components: {
        ClassDefinition,
    },

    methods: {
        /**
         * 등록된 RDF 클래스 목록을 조회한다.
         */
        loadClasses() {
            rest.getClasses().then((response) => {
                //console.log("@.@ getClasses => ", response);
                if (response.data.returnCode == true) {
                    let rs = response.data.result;
                    this.rdfClassList = rs.map((e) => {
                        e.propCount = e.properties.length;
                        // e.properties = e.properties.map((p) => {
                        //     p.propertyName = p.propertyPK.propertyName;
                        //     delete p.propertyPK;
                        //     return p;
                        // });
                        return e;
                    });
                    //console.log("@.@ New Classes => ", this.rdfClassList);
                } else {
                    messageBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         * "클래스 추가" 버튼 클릭
         */
        addRdfClass() {
            this.isNew = true;
            this.dialogRdfClass = true;

            this.$nextTick(() => {
                this.$refs.classDefine.initComponent();
            });
        },

        /**
         * 클래스 추가 다이얼로그에서 "저장" 버튼 클릭
         */
        saveRdfClass() {
            let newObj = this.$refs.classDefine.getRdfClassInfo();

            if(newObj.rdfClassName.length == 0) {
                messageBox("클래스 이름을 입력하세요");
                return;
            }
                
            if(newObj.rdfClassId.length == 0) {
                messageBox("클래스 ID를 입력하세요");
                return;
            }

            /**
             * 등록한 클래스와 동일한 이름이 있는지 검사한다.
             */
            let ix = this.rdfClassList.findIndex(m => m.rdfClassName === newObj.rdfClassName);
            if (ix != -1) {
                messageBox("'" + newObj.rdfClassName + "' 클래스는 이미 존재합니다.");
                return;
            }
             
            /**
             * 저장을 위한 객체 형태로 변환한다.
             */
            let pros = newObj.properties.map((p) => {
                let newP = {
                    rdfClassName: newObj.rdfClassName,
                    propertyName: p.propertyName,
                    propertyId: p.propertyId,
                    propertyType: p.propertyType,
                    description: p.description,
                };
                return newP;
            });
            let postObj = {
                rdfClassName: newObj.rdfClassName,
                rdfClassId: newObj.rdfClassId,
                oldRdfClassName: newObj.oldRdfClassName,
                oldRdfClassId: newObj.oldRdfClassId,
                properties: pros,
            };

            /**
             * 백엔드 호출
             */
            rest.saveClass(postObj).then((response) => {
                //console.log("@.@ saveClass =>", response, newObj);
                if (response.data.returnCode == true) {
                    if (this.isNew) {
                        this.rdfClassList.push(newObj);
                    } else {
                        if(this.targetObject.rdfClassName !== newObj.rdfClassName ||
                           this.targetObject.rdfClassId !== newObj.rdfClassId) {
                            this.targetObject.rdfClassName = newObj.rdfClassName;
                            this.targetObject.rdfClassId = newObj.rdfClassId;
                        }
                        this.targetObject.propCount = newObj.properties.length;
                    }
                    this.dialogRdfClass = false;
                } else {
                    messageBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         * 테이블에서 편집 아이콘 클릭
         */
        editRdfClass(target) {
            console.log(target);
            this.targetObject = target;

            this.isNew = false;
            this.dialogRdfClass = true;

            this.$nextTick(() => {
                this.$refs.classDefine.setTargetObject(this.targetObject);
            });
        },

        /**
         * 삭제 확인
         */
        confirmDelete(target) {
            this.targetObject = target;
            this.dialogDelete = true;
        },

        /**
         * 삭제 다이얼로그에서 "취소" 클릭
         */
        cancelDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.targetObject = null;
            });
        },

        /**
         * 삭제 다이얼로그에서 "삭제" 클릭
         */
        deleteRdfClass() {
            this.dialogDelete = false;
            if (this.targetObject == null) {
                return;
            }
            let row = this.targetObject;
            /**
             * 삭제
             */
            rest.deleteClass(this.targetObject.rdfClassName).then(
                (response) => {
                    //console.log("@.@ deleteClass => ", response);
                    if (response.data.returnCode == true) {
                        let index = this.rdfClassList.indexOf(row);
                        if (index != -1) this.rdfClassList.splice(index, 1);
                        this.targetObject = null;
                    } else {
                        messageBox(response.data.returnMessage);
                    }
                }
            )
            .catch(err => {
                errorBox(err);
            });

            this.cancelDelete();
        },

        closeAddDialog() {
            this.dialogAdd = false;
            this.targetObject = null;
        },
    },
};
</script>
