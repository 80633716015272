<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <v-card class="mx-auto pa-0">
                    <!-- <v-toolbar color="#1E88E5" dark dense >
                        <v-toolbar-title>클래스 정의</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn small color="#42A5F5" @click="beginDic">
                            속성 추가
                        </v-btn>
                    </v-toolbar> -->
                </v-card>
                <div>
                    <v-text-field
                        label="클래스 이름"
                        hide-details="auto"
                        v-model="className"
                    ></v-text-field>
                    <v-text-field
                        label="클래스 ID"
                        v-model="classId"
                    ></v-text-field>
                </div>
                <v-data-table
                    :headers="headers"
                    :items="properties"
                    sort-by="calories"
                    class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                        <v-icon small @click="confirmDeleteI(item)">
                            mdi-delete
                        </v-icon>
                        <v-icon small class="mr-2" @click="addProperty()">
                            mdi-plus
                        </v-icon>
                    </template>

                    <!-- S: 프로퍼티 이름 수정 -->
                    <template v-slot:item.propertyName="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.propertyName"
                            large
                        >
                            {{ props.item.propertyName }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="props.item.propertyName"
                                    label="Edit"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <!-- E: 프로퍼티 이름 수정 -->

                    <!-- S: 프로퍼티 ID 수정 -->
                    <template v-slot:item.propertyId="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.propertyId"
                            large
                        >
                            {{ props.item.propertyId }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="props.item.propertyId"
                                    label="Edit"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <!-- E: 프로퍼티 ID 수정 -->

                    <!-- S: 프로퍼티 타입 수정 -->
                    <template v-slot:item.propertyType="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.propertyType"
                            large
                        >
                            {{ props.item.propertyType }}
                            <template v-slot:input>
                                <v-radio-group
                                    v-model="props.item.propertyType"
                                    row
                                >
                                    <v-radio
                                        v-for="(item, i) in propTypes"
                                        :key="i"
                                        :label="item"
                                        :value="item"
                                    ></v-radio>
                                </v-radio-group>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <!-- E: 프로퍼티 타입 수정 -->

                    <!-- S: 프로퍼티 설명 수정 -->
                    <template v-slot:item.description="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.description"
                            large
                        >
                            {{ props.item.description }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="props.item.description"
                                    label="Edit"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <!-- E: 프로퍼티 ID 설명 -->

                    <template v-slot:no-data>
                        <v-btn color="primary" @click="addProperty">
                            프로퍼티 추가
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <!-- S:프로퍼티 추가 -->
        <v-dialog v-model="propertyAdd" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ "등록" }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field
                                    v-model="editedItem.propertyName"
                                    label="프로퍼티 이름"
                                    ref="propName"
                                    :rules="[
                                        () =>
                                            !!editedItem.propertyName ||
                                            '프로퍼티 이름을 입력하세요',
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field
                                    v-model="editedItem.propertyId"
                                    label="프로퍼티 ID"
                                    ref="propId"
                                    :rules="[
                                        () =>
                                            !!editedItem.propertyId ||
                                            '프로퍼티 ID를 입력하세요',
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-select
                                    v-model="editedItem.propertyType"
                                    :items="propTypes"
                                    label="프로터티 타입"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field
                                    v-model="editedItem.description"
                                    label="프로퍼티 설명"
                                    ref="propDesc"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeAddDialog">
                        취소
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="saveAddDialog">
                        저장
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- E:프로퍼티 추가 -->

        <!-- S:삭제 확인 -->
        <v-dialog v-model="dialogDelete" max-width="300px">
            <v-card>
                <v-card-title class="text-h6"
                    >선택된 속성을 삭제할까요?</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                        >취소</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deletePropertyRow"
                        >확인</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- E:삭제 확인 -->
    </v-container>
</template>

<script>
import { isEmpty } from "../utils/utils";
import { messageBox } from "../utils/toast";

export default {
    props: {
        isNew: {
            type: Boolean,
            default: true,
        },
        targetObject: {
            type: Object,
            required: false,
            default: null,
        }
    },

    data: () => ({
        /**
         * 클래스 추가 다이얼로그
         */
        dialogRdfClass: false,

        /**
         * 삭제 다이얼로그
         */
        dialogDelete: false,

        /**
         * 프로퍼티 추가
         */
        propertyAdd: false,

        /**
         * 클래스 이름 및 ID
         */
        className: "",
        classId: "",
        oldClassName: "",
        oldClassId: "",

        headers: [
            {
                text: "프로퍼티 이름",
                align: "start",
                sortable: false,
                value: "propertyName",
            },
            {
                text: "프로퍼티 ID",
                value: "propertyId",
                sortable: false,
            },
            {
                text: "프로퍼티 타입",
                value: "propertyType",
                sortable: false,
            },
            {
                text: "프로퍼티 설명",
                value: "description",
                sortable: false,
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                width: "10%",
            },
        ],

        propTypes: ["literal", "class", "doc", "url", "html", "table", "comment"],

        /**
         * 프로퍼티 정보를 저장하는 배열
         */
        properties: [],

        /**
         * 선택된 노드
         */
        selectedProp: null,

        editedItem: {
            propertyId: "",
            propertyName: "",
            propertyType: "literal",
            description: "",
        },
    }),

    methods: {
        /**
         * 초기화
         */
        initComponent() {
            this.className = "";
            this.classId = "";
            this.oldClassName = "";
            this.oldClassId = "";
            this.properties = [];
        },

        /**
         * 수정 데이터 설정
         */
        setTargetObject(target) {
            this.className = target.rdfClassName;
            this.classId = target.rdfClassId;
            this.oldClassName = target.rdfClassName;
            this.oldClassId = target.rdfClassId;
            this.properties = target.properties;
        },

        /**
         * 신규 또는 수정 중인 RDF 클래스 정보를 반환한다.
         */
        getRdfClassInfo() {
            let regex = / /gi;
            return {
                rdfClassName: this.className.replace(regex, ''),
                rdfClassId: this.classId.replace(regex, ''),
                oldRdfClassName: this.oldClassName,
                oldRdfClassId: this.oldClassId,
                properties: this.properties,
                propCount: this.properties.length
            };
        },

        /**
         * 프로퍼티 추가
         */
        addProperty() {
            /**
             * 입력영역 초기화
             */
            this.editedItem.propertyName = "";
            this.editedItem.propertyId = "";
            this.editedItem.propertyType = "literal";
            this.editedItem.description = "";

            this.propertyAdd = true;
        },

        closeAddDialog() {
            this.propertyAdd = false;
            this.selectedProp = null;
        },

        /**
         * 프로퍼티를 등록한다.
         */
        saveAddDialog() {
            let regex = / /gi;
            this.editedItem.propertyName = this.editedItem.propertyName.replace(regex, '');
            this.editedItem.propertyId = this.editedItem.propertyId.replace(regex, '');

            if (isEmpty(this.editedItem.propertyName)) {
                messageBox("프로퍼티 이름을 입력하세요.");
                return;
            }
            if (isEmpty(this.editedItem.propertyId)) {
                messageBox("프로퍼티 ID를 입력하세요.");
                return;
            }

            /**
             * 속성이 이미 등록되어 있는지 검사한다.
             */
            // let ix = this.properties.findIndex(m => {
            //     return m.propertyName === this.editedItem.propertyName ||  m.propertyId === this.editedItem.propertyId
            // });

            // if(ix != -1) {
            //     messageBox("동일한 프로퍼티가 존재합니다.");
            //     return;
            // }

            this.properties.push({
                ...this.editedItem,
            });

            this.closeAddDialog();
        },

        /**
         * 삭제 확인창
         */
        confirmDeleteI(item) {
            this.selectedProp = item;
            this.dialogDelete = true;
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.selectedProp = null;
            });
        },

        /**
         * 삭제
         */
        deletePropertyRow() {
            this.dialogDelete = false;

            if (this.selectedProp == null) {
                return;
            }

            let index = this.properties.indexOf(this.selectedProp);
            if (index != -1) this.properties.splice(index, 1);
            this.selectedProp = null;
        },
    },
};
</script>

<style></style>
