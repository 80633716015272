import _ from "lodash";

const rdfMetaManager = new Map();

/**
 * RDF 메타 정보를 등록한다.
 * 
 * @param {*} key 
 * @param {*} meta 
 */
const addMeta = function(key, meta) {
    rdfMetaManager.set(key, meta);
}

/**
 * RDF 메타 정보를 가져온다.
 * 
 * @param {*} key 
 * @returns 
 */
const getMeta = function(key) {
    return rdfMetaManager.get(key);
}

/**
 * 지정된 RDF 프로퍼티를 지정된 값으로 채워 반환한다.
 * @param {*} key 
 * @param {*} value 
 */
const fillProperty = function(key, value) {
    let meta = rdfMetaManager.get(key);
    let pros = [];
    if ( meta != undefined ) {
        // console.log("#### ", meta, value);
        meta.properties.forEach( p => {
            let pv = _.cloneDeep(p);
            let vv = value[pv.propertyName];
            pv.inputValue = vv == undefined ? "" : vv;

            pros.push(pv);
        });
    }

    return pros;
}

/**
 * 클래스 정의에서 flagRoot가 'Y'인 메타 정보를 반환한다.
 */
const getRootClass = function() {
    let root = [];
    rdfMetaManager.forEach(v => {
        if ( v.flagRoot === "Y" )
            root.push(v);
    });

    return root;
}

/**
 * 지정된 클래스의 하위 클래스에 대한 메타 정보를 반환한다.
 * @param {*} className 
 */
const getChildClass = function(className) {
    let target = rdfMetaManager.get(className);
    let cls = [];
    if ( target != undefined ) {
        let clsProp = target.properties.filter(p => p.propertyType === "class");
        clsProp.forEach(p => {
            let m = rdfMetaManager.get(p.propertyName);
            if ( m != undefined )
                cls.push(m);
        });
    }

    return cls;
}

export {
    addMeta,
    getMeta,
    fillProperty,
    getRootClass,
    getChildClass
}