<template>
    <div>
        <v-card class="card-container">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="ml-3 mr-4"
                        small
                        color="success"
                        v-on="on"
                        @click="selectDoc"
                    >편집
                    <v-icon right>mdi-square-edit-outline</v-icon>
                    </v-btn>
                </template>
                <span>수정할 문서를 검색</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-1"
                        small
                        color="secondary"
                        v-on="on"
                        :disabled="root != null"
                        @click="addProduct"
                    >제품 추가
                    <v-icon right>mdi-bank</v-icon>
                    </v-btn>
                </template>
                <span>제품 클래스 추가</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-1"
                        small
                        color="secondary"
                        v-on="on"
                        :disabled="currentNode === null"
                        @click="addRDF"
                    >내용 추가
                    <v-icon right>mdi-source-fork</v-icon>
                    </v-btn>
                </template>
                <span>내용 추가</span>
            </v-tooltip>

            <!-- 소스 추가 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-4"
                        small
                        color="primary"
                        v-on="on"
                        :disabled="addSource"
                        @click="beginSearch"
                    >소스 추가
                    <v-icon right>mdi-animation</v-icon>
                    </v-btn>
                </template>
                <span>자바소스 추가</span>
            </v-tooltip>

            <!-- 초기화 아이콘 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-3"
                        small
                        color="error"
                        v-on="on"
                        @click="initDoc"
                    >초기화
                    <v-icon right>mdi-broom</v-icon>
                    </v-btn>
                </template>
                <span>편집영역 초기화</span>
            </v-tooltip>
            
            <!-- 삭제 아이콘 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        v-on="on"
                        :disabled="buttonDisabled"
                        @click="deleteNode"
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </template>
                <span>선택된 노드 삭제</span>
            </v-tooltip>

            <!-- 편집 아이콘 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        v-on="on"
                        :disabled="buttonDisabled"
                        @click="editNode"
                    >
                        <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                </template>
                <span>선택된 노드를 편집</span>
            </v-tooltip>

            <!-- 상세보기 아이콘 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="showDetails = !showDetails">
                        <v-icon v-show="showDetails">mdi-eye-outline</v-icon>
                        <v-icon v-show="!showDetails"
                            >mdi-eye-off-outline</v-icon
                        >
                    </v-btn>
                </template>
                <span v-show="!showDetails">상세보기 열기</span>
                <span v-show="showDetails">상세보기 닫기</span>
            </v-tooltip>
        </v-card>

        <!-- D3.js를 이용하여 그래프를 표시 -->
        <svg class="d3-tree width-100-percent">
            <g class="container" />
        </svg>

        <!-- 선택한 노드의 상세 정보를 보여준다. -->
        <v-card v-show="showDetails" class="drawer-container">
            <v-card-title >
                {{selectedLabel}}
            </v-card-title>
            <v-card-subtitle class="pb-1">
                {{selectedClassName}}
            </v-card-subtitle>

            <v-card-text>
                <div v-for="(prop, i) in showProperties"
                    :key="i"
                >
                    <PropertyEntry 
                        :propertyName="prop.propertyName"
                        :propertyValue="prop.inputValue"
                        :propertyType="prop.propertyType"
                        :targetObject="currentNode"
                        v-on:moreEvent="handleMoreEvent"
                        :editRank="false"
                    />
                </div>
            </v-card-text>
            <!-- <v-card-actions>
            <v-btn
                text
                color="teal accent-4"
                @click="reveal = true"
            >
                수정
            </v-btn>
            </v-card-actions> -->
        </v-card>

        <!-- 삭제 여부 확인 -->
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title class="headline">삭제</v-card-title>
                <v-card-text class="subtitle-1 text-align-left">
                    선택한 노드를 삭제할까요?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">취소</v-btn>
                    <v-btn color="red" text @click="deleteNodeData()"
                        >삭제</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 신규노드 추가 -->
        <RdfInputPanel
            ref="newDialog"
            v-on:saveEvent="saveTriple" />
        <!-- 자바소스 검색 -->
        <JavaSourceSelector
            ref="javaSelector"
            v-on:selectEvent="addJavaSource" />
        <!-- TOPIC 선택 -->
        <TopicSelector
            ref="topicSelector"
            v-on:selectTopicEvent="selectedTopic" />
        <!-- 노드 편집 -->
        <RdfEditPanel
            ref="editDialog"
            v-on:saveEvent="updateTriple" />
        <!-- markdown 문서 열람 -->
        <DocViewer
            ref="docViewer" />
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import * as d3 from "d3";
import _ from "lodash";
import RdfInputPanel from "../components/RdfInputPanel.vue";
import RdfEditPanel from "../components/RdfEditPanel.vue";
import PropertyEntry from "../components/PropertyEntry.vue";
import JavaSourceSelector from "../components/JavaSourceSelector.vue";
import TopicSelector from "../components/TopicSelector.vue";
import {/*messageBox,*/ errorBox, messageBox} from "../utils/toast";
import * as rdf from "../api/rdf";
import {getClasses} from "../api/model";
import {addMeta, /*getMeta, fillProperty,*/ getRootClass, getChildClass} from "../utils/MetaManager";
import DocViewer from "../components/DocViewer.vue";

export default {
    name: "KnowledgeInputView",
    components: {
        RdfInputPanel,
        PropertyEntry,
        JavaSourceSelector,
        TopicSelector,
        RdfEditPanel,
        DocViewer,
        // MarkdownDisplay
        // Viewer
    },

    data() {
        return {
            /**
             * - D3.js에서 사용하는 SVG
             * - d3.select 메소드를 이용하여 선택
             */
            svg: null,
            /**
             * <g> SVG에 그려지는 shape를 그룹핑
             */
            container: null,
            /**
             * 트리의 노드 생성을 위한 생성자
             */
            NodeObj: null,

            /**
             * 정보 출력을 위한 프로퍼티 목록
             */
            showProperties: [],

            /**
             * 선택된 노드의 라벨 및 클래스 이름
             */
            selectedLabel: "",
            selectedClassName: "",

            zoom: null,

            showDetails: true,
            nodeId: "",
            nodeName: "",
            index: 0,
            duration: 500,
            root: null,
            nodes: [],
            links: [],
            dTreeData: null,
            margin: { top: 20, right: 90, bottom: 30, left: 90 },
            currentNode: null,
            newNodeName: "",
            rootNodeId: null,
            buttonDisabled: false,
            dialog: false,

            markedText: ""
        };
    },
    mounted() {
        /**
         * RDF 클래스 정보를 조회한다.
         */
        getClasses().then(response => {
            if ( response.data.returnCode == true ) {
                response.data.result.forEach(c => {
                    addMeta(c.rdfClassName, c);
                });
            }
            else {
                errorBox(response.data.returnMessage);
            }
        });

        this.initSvg();
    },
    computed: {
        treemap() {
            return d3.tree().nodeSize([38, 220]);
            //return d3.tree().nodeSize([38, 150]);
        },

        addSource() {
            if ( this.currentNode != null ) {
                let cls = getChildClass(this.currentNode.data.rdfClassName);
                if ( cls.length > 0 ) {
                    return cls[0].rdfClassId !== "CbbClass";
                }
            }
            
            return true;
        }
    },

    methods: {
        handleMoreEvent(markdown) {
            this.$nextTick(() => {
                this.$refs.docViewer.showDocument(markdown);
            });
        },

        /**
         * 선택된 노드를 편집한다.
         */
        editNode() {
            if (!this.currentNode) {
                messageBox("편집할 노드를 선택하세요");
                return false;
            }

            this.$refs.editDialog.editTriple(this.currentNode.data);
        },        

        /**
         * 수정된 Triple 정보를 저장한다.
         */
        updateTriple(target) {
            let data = this.currentNode.data;

            // 상위 연결 정보
            target.parentSubject = data.parentLabel;
            target.parentProperty = data.rdfClassName;

            //console.log("@.@ FINAL = ", this.currentNode, target);

            /**
             * 백엔드 호출
             */
            rdf.updateTriple(target).then(response => {
                if (response.data.returnCode == true) {
                    // 표시 값 (subject)
                    if ( target.changeSubject === true ) {
                        let uri = data.resourceUri.split(":");
                        data.label = target.subject.newValue;
                        data.resourceUri = uri[0] + ":" + target.subject.newValue;
                    }

                    // 프로퍼티 값
                    target.properties.forEach(p => {
                        let ix = data.properties.findIndex(t => t.propertyId == p.propertyId);
                        if ( ix != -1 ) {
                            data.properties[ix].resourceUri = p.newValue;
                            data.properties[ix].inputValue = p.newValue;
                        }
                        else {
                            let np = _.cloneDeep(p);
                            np.resourceUri = p.newValue;
                            np.inputValue = p.newValue;
                            data.properties.push(np);
                        }
                    });

                    // 변경된 내용 반영
                    this.showDetail(this.currentNode);

                    let parent = this.currentNode.parent;
                    this.update(parent)
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         * 주제선택
         */
        selectedTopic(topic) {
            this.initDoc();
            this.$nextTick(() => {
                //console.log("@.@ TARGET = ", topic.resourceUri);
                this.getTriple(topic.resourceUri);
            });
        },

        /**
         * 수정할 문서를 선택한다.
         */
        selectDoc() {
            rdf.getRoot("bwg:제품").then((response) => {
                if (response.data.returnCode == true) {
                    if (response.data.result.length == 0) {
                        messageBox("결과가 없습니다.");
                    }
                    else if (response.data.result.length == 1) {
                        let obj = response.data.result[0];
                        //console.log("@.@ TARGET = ", obj);
                        this.initDoc();
                        this.$nextTick(() => {
                            this.getTriple(obj.resourceUri);
                        });
                    } else {
                        // console.log("@.@ SELECT DOC = ", response);
                        let resources = response.data.result.map((m) => {
                            return {
                                resourceUri: m.resourceUri,
                                value: m.inputValue,
                            };
                        });
                        this.$refs.topicSelector.beginSearch(resources);
                    }
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

                /**
         * 지정된 subject의 정보를 가져온다.
         */
        getTriple(subject) {
            rdf.getTriple(subject).then((response) => {
                if (response.data.returnCode == true) {
                    console.log("@.@ GET TRIPLE =", response);
                    this.putNode(response.data.result);
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         * 지정된 노드를 추가한다.
         */
        putNode(target) {
            let nodeData = {
                // 노드에 표시될 타이틀
                label: target.inputValue,
                resourceUri: target.resourceUri,
                // RDF 클래스 정보
                rdfClassId: target.meta.rdfClassId,
                rdfClassName: target.meta.rdfClassName,
                // 프로퍼티 (프로퍼티에 대한 메타 정보 포함)
                properties: target.properties,
                hasClass: target.hasClass,

                // 부모 노드 정보
                parentLabel: "",
                parentClassId: "",
                parentClassName: "",

                value: 1,
            };

            if (target.meta.rdfClassId !== "Product") {
                nodeData.parentLabel = this.currentNode.data.label;
                nodeData.parentClassId = this.currentNode.data.rdfClassId;
                nodeData.parentClassName = this.currentNode.data.rdfClassName;

                this.addNode(nodeData);
            } else {
                this.rootNodeId = nodeData.value;
                this.root = this.getRoot(nodeData);
                this.currentNode = this.root;

                console.log("@.@ ROOT =>", this.root);

                this.$nextTick(() => {
                    this.update(this.root);
                });
            }
        },

        /**
         * JavaSourceSelector에서 업무기능 또는 소스파일을 선택한 경우 호출된다.
         */
        addJavaSource(src, domain, mode) {
            /**
             * 현재 선택된 노드가 없는 경우 무시
             */
            if ( this.currentNode == null )
                return;

            let targetData = {
                targetValue: "",
                domain: domain,
                parentLabel: this.currentNode.data.label,
                parentClassId: this.currentNode.data.rdfClassId,
                parentClassName: this.currentNode.data.rdfClassName,
            };

            if ( mode === "fn" ) {
                if ( src === undefined || src.length == 0 )
                    return;

                targetData.targetValue = src;
                rdf.addJavaBulk(targetData).then(response => {
                    if ( response.data.returnCode == true ) {
                        // console.log("@.@ ADD BULK = ", response.data.result);
                        response.data.result.forEach(js => {
                            js.hasClass = true;
                            this.putNode(js);
                        });
                        this.$nextTick(() => {
                            this.update(this.currentNode);
                        });
                    }
                    else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch(err => {
                    errorBox(err);
                });
            }
            else {
                if ( src.length == 0 )
                    return;
                
                targetData.targetValue = src[0].orgSubject;
                rdf.addJavaSingle(targetData).then(response => {
                    if ( response.data.returnCode == true ) {
                        // console.log("@.@ ADD SINGLE = ", response.data.result);
                        let js = response.data.result;
                        js.hasClass = true;
                        this.putNode(js);

                        this.$nextTick(() => {
                            this.update(this.currentNode);
                        });
                    }
                    else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch(err => {
                    errorBox(err);
                });
            }
        },

        /**
         * 자바소스를 검색한다.
         */
        beginSearch() {
            this.$refs.javaSelector.beginSearch();
        },

        /**
         * 내용 추가
         */
        addRDF() {
            if ( this.currentNode != null ) {
                let cls = getChildClass(this.currentNode.data.rdfClassName);
                if ( cls.length == 1 ) {
                    this.$refs.newDialog.newTriple(cls[0]);
                }
            }
        },

        /**
         * 제품 클래스 추가
         */
        addProduct() {
            if ( this.root == null ) {
                let root = getRootClass();
                if ( root.length == 1 ) {
                    this.$refs.newDialog.newTriple(root[0]);
                }
            }
        },   

        /**
         * 입력 다이얼로그에서 저장이 클릭된 경우
         */
        saveTriple(rs) {
            //console.log("@.@ SAVE DATA =>", rs);
            
            let nodeData = {
                // 문서 ID
                uuid: uuidv4(),
                // 노드에 표시될 타이틀
                label: rs.labelValue,
                // RDF 클래스 정보
                rdfClassId: rs.meta.rdfClassId,
                rdfClassName: rs.meta.rdfClassName,
                // 프로퍼티 (프로퍼티에 대한 메타 정보 포함)
                properties: rs.properties,

                // 부모 노드 정보
                parentLabel: "",
                parentClassId: "",
                parentClassName: "",

                value: 1,
            };

            if ( rs.meta.rdfClassId !== "Product" ) {
                nodeData.parentLabel = this.currentNode.data.label;
                nodeData.parentClassId = this.currentNode.data.rdfClassId;
                nodeData.parentClassName = this.currentNode.data.rdfClassName;
            }

            /**
             * 백앤드 서비스 호출
             */
            rdf.saveKnowledge(nodeData).then(response => {
                console.log("@.@ saveKnowledge RESULT:", response, nodeData);

                if (response.data.returnCode == true) {
                    delete nodeData['uuid'];
                    nodeData.resourceUri = response.data.result.resourceUri;
                    nodeData.properties = response.data.result.properties;

                    if ( rs.meta.rdfClassId === "Product" ) {
                        this.rootNodeId = nodeData.value;
                        this.root = this.getRoot(nodeData);
                        this.update(this.root);
                    }
                    else {
                        if ( this.currentNode != null )
                            this.addNode(nodeData);
                    }
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         * 초기화
         */
        initDoc() {
            let link = this.container.selectAll("g.label-link");
            link.remove();
            let node = this.container.selectAll("g.node");
            node.remove();

            this.$nextTick(() => {
                this.selectedLabel = "";
                this.selectedClassName = "";
                this.showProperties = [];
                this.currentNode = null;
                this.rootNodeId = null;
                this.root = null;
            });
        },

        /**
         * 트리를 출력할 SVG 초기화
         */
        initSvg() {
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            this.width = Math.floor(clientWidth * 0.6);
            this.height = clientHeight - 72;

            let margin = { top: 10, right: 120, bottom: 10, left: 40 };
            let width = this.width;
            let dx = 30;

            /**
             * 트리의 노드 생성을 위한 생성자
             */
            this.NodeObj = d3.hierarchy.prototype.constructor;

            /**
             * SVG가 표시되는 영역의 위치와 크기를 지정한다.
             */
            this.svg = d3
                .select("svg.d3-tree")
                .attr("viewBox", [-margin.left, -margin.top, width, dx]);

            const transform = d3.zoomIdentity
                // .translate(this.margin.left, this.margin.top)
                .translate(0, 0)
                .scale(1);

            this.container = this.svg.select("g.container");

            this.zoom = d3
                .zoom()
                // 스케일 범위를 지정한다. 인자 : [최소 스케일, 최대 스케일]
                .scaleExtent([1 / 4, 4])
                .on("zoom", function () {
                    d3.select("g.container")    // SVG
                      // d3.event.transform는 마우스가 가리키는 위치에서 줌을 시작한다.
                      .attr("transform", d3.event.transform);
                });
            this.container
                .transition()
                .duration(500)
                .call(this.zoom.transform, transform);

            // 이벤트
            this.svg
                // 클릭 후 이동 (패닝)
                .call(this.zoom)
                // 더블클릭 시에 확대하는 기능을 제거, 아래 코드가 없으면 더블클릭 시에 확대 된다.
                .on("dblclick.zoom", null);
        },

        /**
         * 속성을 보여주는 영역 초기화
         */
        initDrawer() {
            this.selectedLabel = "";
            this.selectedClassName = "";
            this.showProperties = [];
            this.currentNode = null;
        },

        /**
         * 컴포넌트, 업무영역, 업무기능 등의 노드 추가
         */
        addNode(nodeData) {
            let parent = this.currentNode;
            const child = Object.assign(new this.NodeObj(), {
                parent,
                depth: parent.depth + 1,    // 계층적 구조를 생성하는데 사용
            }); // eslint-disable-line
            let value = parseInt(Math.random() * 9999, 10) + 1;
            child.value = value;
            child.data = {
                ...nodeData,
                value: value,
                selected: false,
            };

            if (parent.children) parent.children.push(child);
            else parent.children = [child];

            this.nodes.push(child);
            this.links.push({ source: parent, target: child });

            this.update(parent);

            return child;
        },

        /**
         * 선택된 노드를 삭제한다.
         */
        deleteNodeData() {
            this.dialog = false;

            let data = this.currentNode.data;
            if ( data != undefined ) {
                console.log("@.@ DELETE NODE = ", this.currentNode);

                //let pos = data.properties.findIndex(m => m.propertyId == "uuid");
                rdf.deleteNode(data.resourceUri, data.rdfClassName).then(response => {
                    if (response.data.returnCode == true) {
                        /**
                         * 화면에서 삭제
                         */
                        if ( this.currentNode.parent == null ) {
                            // parent가 null인 경우 루트 이므로 화면을 초기화 한다.
                            this.initDoc();
                        }
                        else if (this.currentNode.parent && this.currentNode.parent.children.length > 0) {
                            this.currentNode.parent.children.filter((item, i) => {
                                if (
                                    this.currentNode &&
                                    item.data &&
                                    item.data.value === this.currentNode.data.value
                                ) {
                                    this.currentNode.parent.children.splice(i, 1);
                                    if (this.currentNode.parent.children.length === 0) {
                                        delete this.currentNode.parent.children;
                                    }
                                    this.update(this.currentNode);
                                    this.initDrawer();
                                }
                            });
                        }
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch(err => {
                    errorBox(err);
                });
            }
        },

        /**
         * 삭제 다이얼로그를 출력한다.
         */
        deleteNode() {
            if (!this.currentNode) {
                messageBox("삭제할 노드를 선택하세요");
                return false;
            }

            this.dialog = true;
        },

        // async handleDrop(evt) {
        //     evt.preventDefault();
        //     if (evt.dataTransfer.files && evt.dataTransfer.files.length) {
        //         let file = evt.dataTransfer.files[0];
        //         console.log("file: ", file); // eslint-disable-line
        //     }
        // },
        // goBack() {
        //     if (this.$route.params.data) {
        //         if (this.$route.params.data.searchType === "keyword") {
        //             this.$router
        //                 .push({
        //                     name: "Search",
        //                     params: { data: this.$route.params.data },
        //                 })
        //                 .catch((err) => {}); // eslint-disable-line
        //         } else if (this.$route.params.data.searchType === "title") {
        //             this.$router
        //                 .push({
        //                     name: "ArticleDetail",
        //                     params: { data: this.$route.params.data },
        //                 })
        //                 .catch((err) => {}); // eslint-disable-line
        //         }
        //     } else {
        //         this.$router.push({ name: "ArticleDetail" }).catch((err) => {}); // eslint-disable-line
        //     }
        // },

        /**
         * 주어진 계층구조의 데이터에서 root 노드를 바환한다.
         */
        getRoot(treeData) {
            /**
             * 계층구조 데이터를 이용하여 root 노드를 생성한다.
             */
            let root = d3.hierarchy(treeData, (d) => {
                return d.children;
            });
            root.x0 = this.height / 2;
            root.y0 = 0;
            return root;
        },

        /**
         * 더블클릭으로 노드를 확장하거나 축소한다.
         */
        dblclickNode(d) {
            if (d.children) {
                this.$set(d, "_children", d.children);
                d.children = null;
            } else {
                this.$set(d, "children", d._children);
                d._children = null;
            }
            this.$nextTick(() => {
                this.update(d);
            });
        },

        /**
         * 노트 클릭
         */
        clickNode(d) {
            if (this.currentNode) {
                this.currentNode.selected = false;
            }

            console.log("@.@ CLICK =>", d);

            /**
             * class 노드가 있는 경우 확장 추가
             */
            if (d.data.hasClass == true) {
                let nlist = d.data.properties
                    .filter((m) => m.propertyType == "class")
                    .map((m) => {
                        return {
                            rdfClassName: m.propertyName,
                            resourceUri: m.resourceUri,
                            inputValue: m.inputValue,
                        };
                    });

                d.data.hasClass = false;

                rdf.getTriples(nlist).then((response) => {
                    if (response.data.returnCode == true) {
                        //console.log("@.@ TRIPLES =", response, d);

                        /**
                         * 현재 선택된 노드에 검색한 노드를 추가한다.
                         */
                        response.data.result.forEach((m) => {
                            /**
                             * 선택된 노드에 동일한 노드가 있는지 검사한다.
                             */
                            if ( d.children !== undefined ) {
                                if ( d.children.findIndex(c => c.data.label === m.inputValue) == -1 )
                                    this.putNode(m);
                            }
                            else {
                                this.putNode(m);
                            }
                        });
                        this.$nextTick(() => {
                            this.update(d);
                        });
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                });
            }

            // 상세정보 표시
            this.showDetail(d);

            d.selected = true;
            this.currentNode = d;

            // if (d.children) {
            //     this.$set(d, "_children", d.children);
            //     d.children = null;
            // } else {
            //     this.$set(d, "children", d._children);
            //     d._children = null;
            // }
            // this.$nextTick(() => {
            //     this.update(d);
            // });

            this.$nextTick(() => {
                this.update(d);
            });
        },

        /**
         * 선택된 노드의 상세 정보를 출력한다.
         */
        showDetail(d) {
            this.showProperties = [];
            this.showProperties = this.showProperties.concat(
                d.data.properties.filter((m) => m.propertyType != "class" && m.propertyId != "uuid" && m.propertyId != "rdfClassName")
            );

            this.selectedClassName = d.data.rdfClassName;
            this.selectedLabel = d.data.label;

            this.nodeId = d.data.value;
            this.nodeName = d.data.label;
        },

        diagonal(s, d) {
            return `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;
        },
        /**
         * 루트 노드의 자식 노드와 링크를 가져온다.
         */
        getNodesAndLinks() {
            this.dTreeData = this.treemap(this.root);
            /**
             * 루트의 자식 노드 목록을 가져온다. (루트 포함)
             */
            this.nodes = this.dTreeData.descendants();
            /**
             * 노드간의 링크 정보를 가져온다.
             * - source
             * - target
             */
            this.links = this.dTreeData.descendants().slice(1);
        },

        /**
         * 트리 데이터를 DOM에 바인딩한다.
         */
        update(source) {
            /**
             * this.node - 트리 구조의 노드를 1차원 노드 배열로 저장한다.
             * this.link - 링크가 필요한 노드 정보를 1차원 배열로 저장한다.
             */
            this.getNodesAndLinks();
            //console.log("@.@ update", this.nodes, this.links);

            /**
             * node 변수에는 추가되거나 빠진 노드 정보가 있다.
             */
            let node = this.container
                .selectAll("g.node")
                .data(this.nodes, (d) => {
                    return d.id || (d.id = ++this.index);
                });

            /**
             * nodeEnter 변수는 새로 등록된 노드를 가리킨다.
             */
            let nodeEnter = node
                .enter()
                .append("g")
                .attr("class", "node") // 스타일 지정: node
                .on("dblclick", this.dblclickNode) // 노드 더블클릭
                .attr("transform", (/*d*/) => {
                    // 노드 이동
                    return "translate(" + source.y0 + "," + source.x0 + ")";
                })
                .on("click", this.clickNode); // 노드 클릭

            /**
             * 새 노드에 원을 그린다.
             */
            nodeEnter
                .append("circle")
                .attr("class", "node")
                //.attr("r", 1e-6)
                // .style("fill", function (d) {
                //     return d._children ? "#c9e4ff" : "#fff";
                // })
                // .on("mouseover",function () {return d3.select(this).style("fill","salmon").attr("r",12);})
                // .on("mouseout",function () {return d3.select(this).style("fill", "#fff").attr("r",10);})
                .append("title")
                .text(function (d) {
                    // Tooltip 텍스트 추가한다.
                    return d.data.label;
                });
            // @.@ title 밑에 있으면 동작하지 않는다.
            //
            // .on("click", function () {
            //     let self = this;
            //     setTimeout(() => {
            //         d3.select(self)
            //             .transition()
            //             .delay(1)
            //             .style("fill", function () {
            //                 return "#54a8ff";
            //             })
            //             .style("stroke-width", function () {
            //                 return "3px";
            //             });
            //     }, 100);
            // });

            /**
             * 새 노드에 텍스트를 추가한다.
             */
            nodeEnter
                .append("text")
                .attr("dy", ".35em")
                .attr("x", function (d) {
                    return d.children || d._children ? -14 : 14; // X축의 텍스트 출력 위치를 결정한다.
                })
                .attr("text-anchor", function (d) {
                    return d.children || d._children ? "end" : "start";
                })
                .attr("style", "font-size: 10px;")
                .text(function (d) {
                    return d.data.label.length > 20
                        ? d.data.label.substring(0, 20) + "..."
                        : d.data.label;
                });

            // Transition nodes to their new position.
            let nodeUpdate = nodeEnter
                .merge(node)
                .transition()
                .duration(this.duration)
                .attr("transform", function (d) {
                    return "translate(" + d.y + "," + d.x + ")";
                });

            /**
             * 추가된 원의 속성을 변경한다.
             */
            nodeUpdate
                .select("circle.node")
                .attr("r", 10) // 원의 반지름
                .style("fill", function (d) {
                    return d._children ? "lightsteelblue" : d.selected ? "#81D4FA" : "#fff"; // lightsteelblue: 노드가 축소된 경우의 색상
                })
                .style("stroke-width", function () {
                    // 원 외곽선
                    return "2px";
                })
                .attr("cursor", "pointer");

            /**
             * 추가된 노드의 테스트 투명도 조정
             */
            nodeUpdate.select("text").style("fill-opacity", 1);

            /**
             * 노드의 텍스트 속성을 변경한다.
             */
            node.select("text")
                .attr("dy", ".35em") // 텍스트의 출력위치를 조정한다
                .attr("x", function (d) {
                    return d._children ? 14 : d.children || d._children ? -14 : 14;
                })
                .attr("text-anchor", function (d) {
                    return d._children ? "start" : d.children || d._children ? "end" : "start";
                })
                .attr("style", "font-size: 10px;")
                .text(function (d) {
                    if(d._children)
                        return d.data.label.length > 20
                            ? d.data.label.substring(0, 20) + "..."
                            : d.data.label;
                    if(d.children || d._children)
                        return d.data.label.length > 6
                            ? d.data.label.substring(0, 6) + "...  "
                            : d.data.label;
                    else
                        return d.data.label.length > 20
                            ? d.data.label.substring(0, 20) + "..."
                            : d.data.label;
                });


            /**
             * 제외대상은 삭제한다.
             */
            // Transition exiting nodes to the parent's new position.
            let nodeExit = node
                .exit()
                .transition()
                .duration(this.duration)
                .attr("transform", function (d) {
                    console.log(d);
                    return "translate(" + source.y + "," + source.x + ")";
                })
                .remove();

            nodeExit.select("circle").attr("r", 1e-6);

            nodeExit.select("text").style("fill-opacity", 1e-6);

            // *************************** Links section *************************** //
            // Update the links…
            let link = this.container
                .selectAll(".label-link")
                .data(this.links, (d) => {
                    return d.id;
                });

            // Enter any new links at the parent's previous position.
            let linkG = link
                .enter()
                .insert("g", "g")
                .attr("class", "label-link");

            linkG
                .insert("path")
                // .attr("class", "link")
                .attr("fill", "none")
                .attr("stroke-width", 2)
                .attr("stroke", "#eee");
            // .attr("d", (d) => { // 노드 사이를 연결
            //     console.log("# LINK =====>", d);
            //     return this.diagonal(d, d.parent);
            // });
            linkG
                .append("text")
                //.attr("class", "link-text")
                .attr("dy", ".35em")
                .attr("style", "font-size: 9px;")
                .attr("fill", "blue")
                // .style("fill-opacity", 1)
                .attr("text-anchor", "middle")
                .text(function (d) {
                    return d.data.rdfClassName;
                }).call(function (selection) {
                    selection.each(function(d){d.bbox = this.getBBox();});
                });

            // linkG
            //     .insert("rect","text")
            //     .attr("width", function(d){return d.bbox.width})
            //     .attr("height", function(d){return d.bbox.height})
            //     .style("fill", "yellow");

            // Transition links to their new position.
            let linkUpdate = linkG.merge(link);
            /**
             * path 위치 조정
             */
            linkUpdate
                .select("path")
                .transition()
                .duration(this.duration)
                .attr("d", (d) => {
                    return this.diagonal(d, d.parent);
                });

            /**
             * path 위의 텍스트 위치 조정
             */
            linkUpdate
                .select("text")
                .transition()
                .duration(this.duration)
                .attr("transform", function (d) {
                    return (
                        "translate(" +
                        (d.parent.y + d.y) / 2 +
                        "," +
                        (d.parent.x + d.x) / 2 +
                        ")"
                    );
                });

            // linkUpdate
            //     .select("rect")
            //     .transition()
            //     .duration(this.duration)
            //     .attr("transform", function (d) {
            //         let x = (d.parent.x + d.x) / 2 - d.bbox.width / 2;
            //         let y = (d.parent.y + d.y) / 2 - d.bbox.height / 2;
            //         return "translate(" + (y-5) + "," + (x+5) + ")";
            //     });

            // Transition exiting nodes to the parent's new position.
            link.exit()
                .transition()
                .duration(this.duration)
                .attr("d", (/*d*/) => {
                    //console.log("@.@ EXIT ==>", d);
                    let o = { x: source.x, y: source.y };
                    return this.diagonal(o, o);
                })
                .remove();
        
            // //Transition exiting link text to the parent's new position.
            // linktext.exit().transition().remove();

            // Stash the old positions for transition.
            this.nodes.forEach((d) => {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        },
    },
};
</script>
<style>
/* .d3-tree circle {
    fill: #fff;
    stroke: #54a8ff;
    stroke-width: 2px;
} */

.d3-tree circle {
    fill: #fff;
    stroke: #B0BEC5;
    stroke-width: 2px;
}

/* .d3-tree .node text {
    font: 12px sans-serif;
    color: red;
} */

.d3-tree .link {
    fill: none;
    stroke: #eee;
    stroke-width: 2px;
}

.d3-tree .link-text {
    font: 12px sans-serif;
    color: rgb(25, 0, 255);
}
</style>
<style scoped>
.card-container {
    padding: 4px;
    text-align: left;
}

.display-flex {
    display: flex;
}

.width-100-percent {
    width: 100%;
    height: calc(100vh - 74px);
}

.drawer-container {
    width: 350px;
    position: fixed;
    top: 110px !important;
    right: 14px !important;
    right: 0;
    z-index: 0;
    padding: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

.text-height {
    height: 48px;
    margin-top: 20px;
}

.span-title {
    font-weight: 600;
    margin-right: 20px;
}

.span-id-margin {
    margin-right: 20px;
}
</style>
