<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-overlay :value="isLoadColumn || isLoadErd">
                <v-progress-circular
                    :size="50"
                    :opacity="0.1"
                    indeterminate
                    color="amber">
                </v-progress-circular>
            </v-overlay>
            <v-card>
                <v-toolbar dense dark color="light-blue darken-4">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>테이블 메타정보</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="3">
                                        <v-radio-group
                                            v-model="autoType"
                                            row
                                            hide-details="auto"
                                            class="mt-1 pt-0"
                                        >
                                            <v-radio
                                                label="ID"
                                                value="I"
                                            ></v-radio>
                                            <v-radio
                                                label="NAME"
                                                value="N"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="9">
                                        <v-autocomplete
                                            class="mt-0 pt-0"
                                            v-model="selectedTable"
                                            :items="searchedTables"
                                            :loading="isLoading"
                                            :search-input.sync="search"
                                            hide-no-data
                                            hide-selected
                                            clearable
                                            :item-text="itemText"
                                            :item-value="itemValue"
                                            :placeholder="placeholderText"
                                            prepend-icon="mdi-database-search"
                                            :return-object="true"
                                            hide-details="auto"
                                            ref="ac"
                                        >
                                            <template v-slot:item="data">
                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        v-text="
                                                            data.item.tableName
                                                        "
                                                    ></v-list-item-title>
                                                    <v-list-item-subtitle
                                                        v-text="
                                                            data.item.tableId
                                                        "
                                                    ></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            dense
                                            :headers="headers"
                                            :items="columnList"
                                            item-key="id"
                                            class="elevation-1 mt-0 pt-0"
                                        >
                                            <template #item.columnId="{ item }">
                                                <div v-if="item.commonCode != null && item.commonCode.length > 0">
                                                    <a @click="showCommonCode(item)">
                                                    {{ item.columnId }}
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    {{ item.columnId }}
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="10">
                                        <v-combobox
                                            ref="rTab"
                                            v-model="erdTable"
                                            :items="relationTables"
                                            :item-text="(m) => m.tableName + ' (' + m.tableId + ')'"
                                            label="관련 테이블"
                                            dense hide-details="auto"
                                        ></v-combobox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-checkbox
                                            v-model="autoLoad"
                                            label="스키마 읽기"
                                            dense hide-details="auto"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <div v-dragscroll class="imgScrollBox" style="margin-top: -16px">
                                            <img :src="erdImage" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <CodeInstanceViewer ref="insViewer" />
    </v-row>
</template>

<script>
import { errorBox, messageBox } from "../utils/toast";
import axios from "axios";
import * as tab from "../api/table";
import { dragscroll } from 'vue-dragscroll';
import CodeInstanceViewer from "./CodeInstanceViewer.vue";

export default {

    directives: {
        dragscroll
    },

    components: {
        CodeInstanceViewer
    },

    data() {
        return {
            /**
             * 다이얼로그 제어 변수
             */
            dialog: false,

            /**
             * ID or NAME
             */
            autoType: "N",
            searchedTables: [],
            selectedTable: null,
            search: null,
            // 자동환성
            isLoading: false,

            isLoadColumn: false,
            isLoadErd: false,

            /**
             * 테이블 정보
             */
            headers: [
                {
                    text: "컬럼명",
                    align: "start",
                    value: "columnName",
                },
                {
                    text: "컬럼ID",
                    value: "columnId",
                },
                {
                    text: "타입",
                    value: "dataType",
                },
                {
                    text: "PK",
                    value: "pk",
                },
                {
                    text: "주석",
                    value: "commentkr",
                },
            ],

            columnList: [],

            erdImage: "",

            /**
             * 관련테이블
             */
            relationTables: [],
            /**
             * 관련테이블을 선택하면 스키마를 자동으로 로드
             */
            autoLoad: true,
        };
    },

    computed: {
        itemText() {
            return this.autoType === 'I' ? "tableId" : "tableName";
        },

        itemValue() {
            return this.autoType === 'I' ? "tableName" : "tableId";
        },

        placeholderText() {
            return this.autoType === 'I' ? "테이블 아이디 입력" : "테이블 이름 입력";
        },

        erdTable: {
            get: function() {
                return null;
            },

            set: function(item) {
                this.$nextTick(() => {
                    if (item != null && typeof(item) == 'object') {
                        this.loadERD(item.tableId, true);
                        if(this.autoLoad) {
                            this.loadSchema(item.tableId, item.tableName, true);
                        }
                    }
                });
            }
        }
    },    

    watch: {
        selectedTable() {
            this.searchMeta();
        },

        /**
         * Autocomplete 데이터 조회 
         */
        search(val) {
            if (this.isLoading) return;
            if (val == null || val.length == 0) return;

            this.isLoading = true;

            let ac = axios.create({	timeout: 30000 });
            let locale = process.env.VUE_APP_I18N_LOCALE || 'ko';
            ac.get(`/v1/tables/auto/${locale}/${val}`)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.searchedTables = response.data.result;
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                })
                .finally(() => (this.isLoading = false));
        },
    },    

    methods: {
        show() {
            this.dialog = true;
        },

        /**
         * 공통코드를 부여준다.
         */
        showCommonCode(item) {
            tab.getInstances(item.commonCode).then(response => {
                if ( response.data.returnCode == true ) {
                    let rs = response.data.result;
                    if ( rs.instances.length > 0 ) {
                        this.$nextTick(()=> {
                            this.$refs.insViewer.show(rs.codeNumber, rs.codeName, rs.codeDescription, rs.instances);
                        });
                    }
                }
                else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            })
            .finally(() => (this.isLoadColumn = false));
        },
       
        /**
         * 테이블 정보 및 ERD 검색
         */
        searchMeta() {
            if (this.selectedTable == null || this.selectedTable.tableId.length == 0) {
                //messageBox("테이블을 선택하세요");
                return;
            }

            /**
             * ERD 다이어그램
             */
            this.loadERD(this.selectedTable.tableId, false);

            /**
             * 테이블 정보
             */
            this.loadSchema(this.selectedTable.tableId, "", false);
        },

        /**
         * 테이블 스키마를 로드한다.
         */
        loadSchema(tableId, tableName, auto) {
            this.isLoadColumn = true;
            tab.getTable(tableId + "|CRUD").then(response => {
                if ( response.data.returnCode == true ) {
                    if ( response.data.result.length > 0 )
                        this.columnList = response.data.result[0].columns;

                        if(auto) {
                            this.$nextTick(()=> {
                                this.isLoading = true;
                                this.$refs.ac.lazySearch = tableName;
                                this.isLoading = false;
                            })
                        }
                }
                else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            })
            .finally(() => (this.isLoadColumn = false));
        },

        /**
         * ERD 다이어그램을 로드한다.
         */
        loadERD(tableId, erdOnly) {
            this.isLoadErd = true;
            tab.getERD(tableId).then((response) => {
                if (response.data.returnCode == true) {
                    if(response.data.result.convertMessage == 'SUCCESS') {
                        this.erdImage = response.data.result.erdImage;
                        if(erdOnly == false) {
                            this.$refs.rTab.lazySearch = ''
                            this.relationTables = response.data.result.relationTables;
                        }
                    }
                    else {
                        this.erdImage = '';
                        if(erdOnly == false) {
                            this.$refs.rTab.lazySearch = ''
                            this.relationTables = [];
                        }
                        messageBox(response.data.result.convertMessage);
                    }
                    
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch((err) => {
                errorBox(err);
            })
            .finally(() => (this.isLoadErd = false));
        }
    },
};
</script>

<style scoped>
.imgScrollBox {
  overflow:scroll;
  width:100%;
  height:530px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ebeaea;
}
</style>