<template>
    <div>
        <div class="p-title" @click="copyUri">
            <p class="p-title">· {{ propertyName }}</p>
        </div>
        <div
            v-if="propertyName !== 'rank' && propertyName !== 'UML' && propertyType !== 'idoc' && propertyType === 'literal'"
            class="p-value"
        >
            {{ propertyValue }}
        </div>
        <div v-if="propertyName == 'UML'">
            <v-btn
                class="ml-1 white--text"
                x-small
                color="light-blue darken-4"
                @click="showUML"
            >
                UML 다이어그램 (실험적단계)
            </v-btn>
        </div>
        <div v-if="propertyType === 'idoc'">
            <v-btn
                class="ml-1"
                x-small
                color="primary"
                outlined
                @click="openUrl"
            >
                More..
            </v-btn>
        </div>        
        <div v-if="propertyType === 'doc' && propertyName !== '개발가이드'">
            <v-btn
                class="ml-1"
                x-small
                color="primary"
                outlined
                @click="clickMore"
                :disabled="propertyValue.length == 0"
            >
                More..
            </v-btn>
        </div>
        <div v-if="propertyType === 'doc' && propertyName === '개발가이드'">
            <v-btn
                class="ml-1"
                x-small
                color="primary"
                outlined
                @click="showGuide"
            >
                More..
            </v-btn>
            <v-btn
                class="ml-1"
                x-small
                color="error"
                outlined
                @click="editGuide"
                :disabled="editRank == false"
            >
                Edit
            </v-btn>
            <v-btn
                class="ml-1"
                x-small
                color="primary"
                outlined
                @click="showHistory"
            >
                History
            </v-btn>
        </div>
        <div v-if="propertyName === 'rank'">
            <v-rating
                class="mt-0 pt-0"
                background-color="indigo lighten-3"
                color="pink"
                small
                :readonly="!editRank"
                :value="
                    propertyValue == undefined || propertyValue.length == 0
                        ? 0
                        : parseInt(propertyValue)
                "
                @input="onRankChange"
            ></v-rating>
        </div>
        <div v-if="propertyType === 'html'">
            <v-btn
                class="ml-1"
                x-small
                color="primary"
                outlined
                @click="showJavadoc"
                :disabled="propertyValue.length == 0"
            >
                More..
            </v-btn>
        </div>
        <div v-if="propertyType === 'comment'">
            <div v-if="checkBadge === true">
                <v-badge bordered color="error" :content="content" inline>
                    <v-btn
                        class="ml-1"
                        x-small
                        color="error"
                        outlined
                        @click="clickComment"
                    >
                        Show
                    </v-btn>
                </v-badge>
            </div>
            <div v-else>
                <v-btn
                    class="ml-1"
                    x-small
                    color="error"
                    outlined
                    @click="clickComment"
                >
                    Show
                </v-btn>
            </div>
        </div>
        <div v-if="propertyType === 'table'">
            <v-btn
                class="ml-1"
                x-small
                color="primary"
                outlined
                @click="showTable"
                :disabled="propertyValue.length == 0"
            >
                More..
            </v-btn>
        </div>
        <div v-if="propertyType === 'url'">
            <v-btn
                class="ml-1"
                x-small
                color="success"
                @click="openUrl"
                :disabled="propertyValue.length == 0"
            >
                Open
            </v-btn>
        </div>
        <HtmlDialog ref="htmlDialog" />
        <TableDialog ref="tableDialog" />
        <CommentDialog ref="commentDialog" />
        <ShareHistoryDialog ref="historyDialog" />
        <IframeViewer ref="iframeViewer" />
        <UMLDialog ref="umlViewer" />
    </div>
</template>

<script>
import HtmlDialog from "./HtmlDialog.vue";
import TableDialog from "./TableDialog.vue";
import CommentDialog from "./CommentDialog.vue";
import ShareHistoryDialog from "./ShareHistoryDialog.vue";
import copy from 'copy-to-clipboard';
import IframeViewer from "./IframeViewer.vue";
import UMLDialog from "./UMLDialog.vue";

export default {
    components: {
        HtmlDialog,
        TableDialog,
        CommentDialog,
        ShareHistoryDialog,
        IframeViewer,
        UMLDialog
    },

    props: {
        propertyName: {
            type: String,
            required: false,
            default: "",
        },
        propertyValue: {
            type: String,
            required: false,
            default: "",
        },
        propertyType: {
            type: String,
            required: false,
            default: "",
        },
        targetObject: {
            type: Object,
            required: false,
        },
        editRank: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            ranking: 0,
            commentCount: 0,
            content: "",
        };
    },

    computed: {
        checkBadge() {
            // if(this.propertyType === 'comment')
            //     this.checkComment();
            return this.commentCount > 0 ? true : false;
        },
    },

    watch: {
        propertyValue() {
            this.checkComment();
        }
    },

    methods: {
        /**
         * UML 보기
         */
        showUML() {
            if ( this.targetObject != null ) {
                console.log(this.targetObject);
                let uri = this.targetObject.data.resourceUri;
                let pos = uri.indexOf(":");
                if(this.targetObject.data.rdfClassName === '구현메소드') {
                    if(pos != -1) {
                        this.$refs.umlViewer.showSequence(uri.substring(pos+1));
                    }
                    else {
                        this.$refs.umlViewer.showSequence(uri);
                    }
                }
                else {
                    if(pos != -1) {
                        this.$refs.umlViewer.showClass(uri.substring(pos+1));
                    }
                    else {
                        this.$refs.umlViewer.showClass(uri);
                    }
                }
            }
        },

        /**
         * URL을 IFRAME으로 표시
         */
        showIframe() {
            this.$refs.iframeViewer.showDocument();
        },

        /**
         * Resource URI 복사
         */
        copyUri() {
            if ( this.targetObject != null ) {
                copy(this.targetObject.data.resourceUri);
            }
        },

        /**
         * 지정된 URL을 새창에 오픈한다.
         */
        openUrl() {
            if ( this.propertyValue != null && this.propertyValue.length > 0 ) {
                window.open(this.propertyValue, "CBP-EXPLORER", "width=1024,height=800,left=10,top=10,location=no");
            }
        },

        /**
         * 개발가이드 변경 요청 이력 조회
         */
        showHistory() {
            this.$refs.historyDialog.showHistory(this.targetObject.data.resourceUri);
        },

        /**
         * 가이드 문서 편집
         */
        editGuide() {
            this.$emit("editEvent", this.targetObject);
        },

        /**
         * 가이드 문서 열람
         */
        showGuide() {
            this.$emit("moreEvent", this.propertyValue);
        },

        /**
         * 코멘트 확인
         */
        checkComment() {
            if (this.propertyType === 'comment' && this.propertyValue.length > 0) {
                let cs = this.propertyValue.split("##");
                this.commentCount = cs.length;
                if (this.commentCount > 9) this.content = "9+";
                else this.content = String(this.commentCount);
            }
            else {
                this.commentCount = 0;
                this.content = "";
            }
        },

        /**
         * 변경된 중요도 업데이트
         */
        onRankChange(newRank) {
            this.$emit(
                "changeRank",
                this.targetObject.data.resourceUri,
                newRank
            );
        },

        /**
         * COMMENT 버튼 클릭
         */
        clickComment() {
            this.$refs.commentDialog.showComment(this.targetObject);
        },
        /**
         * JavaDoc
         */
        showJavadoc() {
            this.$refs.htmlDialog.showHtml(this.propertyValue);
        },
        /**
         * more 버튼 클릭
         */
        clickMore() {
            this.$emit("moreEvent", this.propertyValue);
        },
        /**
         *
         */
        showTable() {
            this.$refs.tableDialog.showTable(this.propertyValue);
        },
    },
};
</script>

<style lang="scss" scoped>
.p-title {
    // background-color: yellow;
    margin: 1px 0 0 0;
    padding: 0 0;
    font-size: 11px;
    font-weight: bold;
}
.p-value {
    color: blue;
    // background-color: red;
    margin: 1px 0 3px 5px;
    padding: 0 0;
}
</style>
