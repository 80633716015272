<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        transition="dialog-bottom-transition"
    >
        <v-btn
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="dialog = false"
            class="mr-7"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-container
            class="grey lighten-4"
            fluid
        >
            <v-row>
                <v-spacer></v-spacer>
                <v-col
                    v-for="(comp, ix) in domains"
                    :key="ix"
                    cols="12"
                    sm="6"
                    md="3"
                >
                    <v-card>
                        <v-card-title>{{comp.componentName}}</v-card-title>
                        <v-card-text>
                            <v-btn
                                outlined block
                                text
                                color="blue darken-3"
                                v-for="(l2, i) in comp.l2"
                                :key="i"
                                class="mt-1"
                                @click="showKnowledge(l2)"
                            >{{l2.l2PackageName}}</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-dialog>
</template>

<script>
import DOMAIN from "../assets/category.json";

export default {
    data() {
        return {
            dialog: false,

            domains: DOMAIN.domains,
        };
    },

    methods: {
        /**
         * 컴포넌트 선택 창을 표시
         */
        showOverview() {
            this.dialog = true;
        },

        /**
         * 선택된 컴포넌트 정보를 전달
         */
        showKnowledge(target) {
            this.$emit("selectEvent", target);
            this.dialog = false;
        }
    },
};
</script>

<style scoped></style>
