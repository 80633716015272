<template>
    <v-dialog
        transition="dialog-top-transition"
        max-width="500"
        v-model="dialog"
    >
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex>
                    <v-card class="elevation-12">
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>ADMIN</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-form>
                                <v-text-field
                                    prepend-icon="mdi-account"
                                    v-model="username"
                                    label="Username"
                                    type="text"
                                ></v-text-field>
                                <v-text-field
                                    id="password"
                                    prepend-icon="mdi-lock"
                                    v-model="password"
                                    label="Password"
                                    type="password"
                                    @keyup.enter="auth"
                                ></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary"
                                   class="mr-2 mb-2 mt-0"
                                   @click="auth"
                                   :disabled="this.username.length == 0 || this.password.length == 0"
                            >Login</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-dialog>
</template>

<script>
import * as users from "../api/users";
import {errorBox} from "../utils/toast";
import { mapMutations } from "vuex";

export default {
    data: () => {
        return {
            dialog: false,
            username: "",
            password: "",
        };
    },

    methods: {
        ...mapMutations(['setAdmin', 'setUserRole']),

        /**
         * 
         */
        login() {
            this.dialog = true;
        },

        /**
         * 
         */
        auth() {
            let userData = {
                username: this.username,
                password: this.password,
            };
            users.login(userData).then((response) => {
                if (response.data.returnCode == true) {
                    this.setUserRole(response.data.result.role);
                    this.setAdmin("Y");
                    
                    this.dialog = false;
                    this.username = "";
                    this.password = "";
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },
    },
};
</script>
