import Vue from "vue";
// import App from "./App.vue";
import Main from './Main.vue'
import store from "./store";
import vuetify from "./plugins/vuetify";
// import VueFriendlyIframe from 'vue-friendly-iframe';
import router from "./router";

/**
 * 로딩 컴포넌트
 * https://github.com/ankurk91/vue-loading-overlay/tree/v3.x
 */
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import i18n from "./i18n";

Vue.use(Loading);
// Vue.use(VueFriendlyIframe);

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    // render: (h) => h(App),
    render: (h) => h(Main),
    i18n,
    store,
}).$mount("#app");
