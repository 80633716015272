<template>
    <v-dialog
        v-model="dialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
    >
        <v-btn
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="dialog = false"
            class="mr-7"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card>
            <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>문서보기</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <!-- <v-btn dark text @click="dialog = false">닫기</v-btn> -->
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <Viewer
                                ref="toastuiViewer"
                                :options="viewerOptions"
                                height="300px"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { Viewer } from "@toast-ui/vue-editor";
import umlPlugin from "@toast-ui/editor-plugin-uml";
// import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
// import tablePlugin from "@toast-ui/editor-plugin-table-merged-cell";
// import 'prismjs/themes/prism.css';
// import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
// import codePlugin from "@toast-ui/editor-plugin-code-syntax-highlight";
// import 'tui-color-picker/dist/tui-color-picker.css';
// import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
// import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
// import '@toast-ui/editor/dist/i18n/ko-kr.js';

export default {
    components: {
        Viewer,
    },

    data() {
        return {
            dialog: false,
            viewerOptions: {
                height: "auto",
                language: "ko-KR",
                // plugins: [tablePlugin, codePlugin, colorSyntax, umlPlugin]
                plugins: [umlPlugin],
            },
        };
    },

    methods: {
        showDocument(markdown) {
            this.dialog = true;
            this.$nextTick(() => {
                this.$refs.toastuiViewer.invoke("setMarkdown", markdown);
            });
        },
    },
};
</script>

<style></style>
