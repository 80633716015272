<template>
    <div>
        <v-card class="card-container">
            <!-- 열람 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-4 ml-1"
                        small
                        color="success"
                        v-on="on"
                        @click="selectDoc"
                        >열람
                        <v-icon right>mdi-chemical-weapon</v-icon>
                    </v-btn>
                </template>
                <span>문서를 열람</span>
            </v-tooltip>

            <!-- Overview -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-4"
                        small
                        color="success"
                        v-on="on"
                        @click="overview"
                        >선택
                        <v-icon right>mdi-apps</v-icon>
                    </v-btn>
                </template>
                <span>컴포넌트 선택</span>
            </v-tooltip>

            <!-- 상품조건 검색 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-4 white--text"
                        small
                        color="light-blue darken-4"
                        v-on="on"
                        @click="productCondition"
                        >상품조건 검색
                        <v-icon right>mdi-format-list-checks</v-icon>
                    </v-btn>
                </template>
                <span>자연어 기반 검색 (실험적 단계)</span>
            </v-tooltip>
            <!-- 상품조건 검색 -->

            <!-- API 검색 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-4"
                        small
                        color="primary"
                        v-on="on"
                        @click="beginSearch"
                        >API 검색
                        <v-icon right>mdi-file-find</v-icon>
                    </v-btn>
                </template>
                <span>자바 API 검색</span>
            </v-tooltip>

            <!-- 테이블 검색 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-4"
                        small
                        color="primary"
                        v-on="on"
                        @click="beginTableSearch"
                        >테이블 검색
                        <v-icon right>mdi-database-search</v-icon>
                    </v-btn>
                </template>
                <span>테이블 검색</span>
            </v-tooltip>

            <!-- 테이블 메타 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-4"
                        small
                        color="light-blue darken-4"
                        dark
                        v-on="on"
                        @click="tableMeta"
                        >테이블스키마 & ERD
                        <v-icon right>mdi-table</v-icon>
                    </v-btn>
                </template>
                <span>테이블 컬럼정보 및 ERD</span>
            </v-tooltip>

            
            <!-- 초기화 아이콘 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mr-3"
                        small
                        color="error"
                        v-on="on"
                        @click="initDoc"
                        >초기화
                    </v-btn>
                </template>
                <span>영역 초기화</span>
            </v-tooltip>
            <!-- 상세보기 아이콘 -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="showDetails = !showDetails">
                        <v-icon v-show="showDetails">mdi-eye-outline</v-icon>
                        <v-icon v-show="!showDetails"
                            >mdi-eye-off-outline</v-icon
                        >
                    </v-btn>
                </template>
                <span v-show="!showDetails">상세보기 열기</span>
                <span v-show="showDetails">상세보기 닫기</span>
            </v-tooltip>
        </v-card>

        <!-- D3.js를 이용하여 그래프를 표시 -->
        <svg class="d3-tree2 width-100-percent">
            <g class="view-container" />
        </svg>

        <!-- 선택한 노드의 상세 정보를 보여준다. -->
        <v-card v-show="showDetails" class="drawer-container">
            <v-card-title>
                {{ selectedLabel }}
            </v-card-title>
            <v-card-subtitle class="pb-1">
                {{ selectedClassName }}
            </v-card-subtitle>

            <v-card-text>
                <div v-for="(prop, i) in showProperties" :key="i">
                    <PropertyEntry
                        :propertyName="prop.propertyName"
                        :propertyValue="prop.inputValue"
                        :propertyType="prop.propertyType"
                        :targetObject="currentNode"
                        v-on:moreEvent="handleMoreEvent"
                        v-on:changeRank="handleChangeRank"
                        v-on:editEvent="handleEditEvent"
                        :editRank="true"
                    />
                </div>
            </v-card-text>
            <!-- <v-card-actions>
                <v-btn text color="teal accent-4" @click="reveal = true">
                    수정
                </v-btn>
            </v-card-actions> -->
        </v-card>

        <!-- 자바소스 검색 -->
        <JavaApiSelector
            ref="javaApiSelector"
            v-on:selectEvent="selectJavaApi" />

        <!-- TOPIC 선택 -->
        <TopicSelector
            ref="topicSelector"
            v-on:selectTopicEvent="selectedTopic"
        />

        <!-- 테이블 검색 -->
        <!-- 자바소스 검색 -->
        <TableMethodSelector
            ref="tableSelector"
            v-on:selectEvent="selectJavaApi" />

        <!-- markdown 문서 열람 -->
        <DocViewer ref="docViewer" />

        <!-- 가이드 문서 수정 -->
        <ShareDialog ref="shareDialog" />

        <!-- 전체 목록에서 선택 -->
        <OverviewDialog 
            v-on:selectEvent="selectComponent"
            ref="overviewDialog" />

        <!-- 상품조건 검색 -->
        <ProductConditionDialog ref="pfSearchDialog" />

        <!-- 테이블 메타 및 ERD -->
        <TableErdViewer ref="erdViewer" />
    </div>
</template>

<script>
import * as d3 from "d3";
import PropertyEntry from "../components/PropertyEntry.vue";
import JavaApiSelector from "../components/JavaApiSelector.vue";
import TableMethodSelector from "../components/TableMethodSelector.vue";
import TopicSelector from "../components/TopicSelector.vue";
import {errorBox, messageBox} from "../utils/toast";
import * as rdf from "../api/rdf";
import { getClasses } from "../api/model";
import { addMeta } from "../utils/MetaManager";
import DocViewer from "../components/DocViewer.vue";
import ShareDialog from "../components/ShareDialog.vue";
import OverviewDialog from "../components/OverviewDialog.vue";
import ProductConditionDialog from "../components/ProductConditionDialog.vue";
import TableErdViewer from "../components/TableErdViewer.vue";

export default {
    name: "KnowledgeInputView",
    components: {
        PropertyEntry,
        JavaApiSelector,
        TopicSelector,
        DocViewer,
        TableMethodSelector,
        ShareDialog,
        OverviewDialog,
        ProductConditionDialog,
        TableErdViewer
    },

    data() {
        return {
            /**
             * - D3.js에서 사용하는 SVG
             * - d3.select 메소드를 이용하여 선택
             */
            svg: null,
            /**
             * <g> SVG에 그려지는 shape를 그룹핑
             */
            container: null,
            /**
             * 트리의 노드 생성을 위한 생성자
             */
            NodeObj: null,

            /**
             * 정보 출력을 위한 프로퍼티 목록
             */
            showProperties: [],

            /**
             * 선택된 노드의 라벨 및 클래스 이름
             */
            selectedLabel: "",
            selectedClassName: "",

            zoom: null,

            showDetails: true,
            nodeId: "",
            nodeName: "",
            index: 0,
            duration: 500,
            root: null,
            nodes: [],
            links: [],
            dTreeData: null,
            margin: { top: 20, right: 90, bottom: 30, left: 90 },
            currentNode: null,
            newNodeName: "",
            rootNodeId: null,
            buttonDisabled: false,
            dialog: false,
        };
    },
    mounted() {
        /**
         * RDF 클래스 정보를 조회한다.
         */
        getClasses().then((response) => {
            if (response.data.returnCode == true) {
                response.data.result.forEach((c) => {
                    addMeta(c.rdfClassName, c);
                });
            } else {
                errorBox(response.data.returnMessage);
            }
        })
        .catch(err => {
            errorBox(err);
        });

        this.initSvg();
    },
    computed: {
        treemap() {
            return d3.tree().nodeSize([38, 250]);
            //return d3.tree().nodeSize([38, 150]);
        },
    },

    methods: {
        /**
         * 데이블 메타정보
         */
        tableMeta() {
            this.$refs.erdViewer.show();
        },

        /**
         * 상품검색 창
         */
        productCondition() {
            this.$refs.pfSearchDialog.show();
        },

        /**
         * 컴포넌트 선택
         */
        selectComponent(subject) {
            // console.log("@.@ SUBJECT = ", subject);
            if ( subject == null )
                return;

            let l2id = "cbp:L2@" + subject.l2PackageName;

            this.initDoc();
            this.$nextTick(() => {
                this.loadTree(l2id);
            });
        },

        /**
         * 
         */
        overview() {
            this.$refs.overviewDialog.showOverview();
        },

        /**
         * 가이드 문서 편집
         */
        handleEditEvent(target) {
            this.$refs.shareDialog.startShare(target);
        },

        /**
         * 중요도 업데이트
         */
        handleChangeRank(uri, newRank) {
            if(this.currentNode != null) {
                rdf.changeRank(uri, newRank).then((response) => {
                    if (response.data.returnCode == true) {
                        let ix = this.currentNode.data.properties.findIndex(m => m.propertyId === 'rank');
                        if ( ix != -1) {
                            this.currentNode.data.properties[ix].inputValue = String(newRank);
                        }
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch(err => {
                    errorBox(err);
                });
            }
        },

        /**
         * 문서
         */
        handleMoreEvent(markdown) {
            this.$nextTick(() => {
                this.$refs.docViewer.showDocument(markdown);
            });
        },

        /**
         * 주제선택
         */
        selectedTopic(topic) {
            this.initDoc();
            this.$nextTick(() => {
                //console.log("@.@ TARGET = ", topic.resourceUri);
                this.getTriple(topic.resourceUri);
            });
        },

        /**
         * 주제를 검색한다.
         */
        selectDoc() {
            rdf.getRoot("bwg:제품").then((response) => {
                if (response.data.returnCode == true) {
                    if (response.data.result.length == 0) {
                        messageBox("결과가 없습니다.");
                    }
                    else if (response.data.result.length == 1) {
                        let obj = response.data.result[0];
                        //console.log("@.@ TARGET = ", obj);
                        this.initDoc();
                        this.$nextTick(() => {
                            this.getTriple(obj.resourceUri);
                        });
                    } else {
                        // console.log("@.@ SELECT DOC = ", response);
                        let resources = response.data.result.map((m) => {
                            return {
                                resourceUri: m.resourceUri,
                                value: m.inputValue,
                            };
                        });
                        this.$refs.topicSelector.beginSearch(resources);
                    }
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         * 지정된 subject의 정보를 가져온다.
         */
        getTriple(subject) {
            rdf.getTriple(subject).then((response) => {
                if (response.data.returnCode == true) {
                    //console.log("@.@ GET TRIPLE =", response);
                    this.putNode(response.data.result);
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         * 지정된 노드를 추가한다.
         */
        putNode(target) {
            let nodeData = {
                // 노드에 표시될 타이틀
                label: target.inputValue,
                resourceUri: target.resourceUri,
                // RDF 클래스 정보
                rdfClassId: target.meta.rdfClassId,
                rdfClassName: target.meta.rdfClassName,
                // 프로퍼티 (프로퍼티에 대한 메타 정보 포함)
                properties: target.properties,
                hasClass: target.hasClass,

                // 부모 노드 정보
                parentLabel: "",
                parentClassId: "",
                parentClassName: "",

                value: 1,
            };

            if (target.meta.rdfClassId !== "Product") {
                nodeData.parentLabel = this.currentNode.data.label;
                nodeData.parentClassId = this.currentNode.data.rdfClassId;
                nodeData.parentClassName = this.currentNode.data.rdfClassName;

                this.addNode(nodeData);
            } else {
                this.rootNodeId = nodeData.value;
                this.root = this.getRoot(nodeData);
                this.currentNode = this.root;
                //this.currentNode.selected = true;

                //console.log("@.@ ROOT =>", this.root);

                this.$nextTick(() => {
                    this.update(this.root);
                });
            }
        },

        /**
         * 자바 API를 검색한다.
         */
        beginSearch() {
            this.$refs.javaApiSelector.beginSearch();
        },

        /**
         * 테이블 검색
         */
        beginTableSearch() {
            this.$refs.tableSelector.beginSearch();
        },

        /**
         * Java API 선택
         */
        selectJavaApi(apiObj) {
            //console.log("@.@ SELECTED = ", apiObj);
            if ( apiObj == null || apiObj.length == 0 )
                return;

            this.initDoc();
            this.$nextTick(() => {
                this.loadTree(apiObj[0].subject);
            });
        },

        /**
         * 지정된 주제의 상위 노드를 로드한다.
         */
        loadTree(subject) {
            //console.log("@.@ TARGET = " + subject);
            rdf.loadTree(subject).then(response => {
                //console.log("@.@ RESPONSE = ", response);
                if (response.data.returnCode == true) {
                    let nodes = response.data.result;
                    if ( nodes.length == 0 )
                        return;

                    /**
                     * 최상위 노드는 화면에 출력하지 않는다.
                     */
                    let rnode = nodes[1];
                    /**
                     * 루트 노드 생성
                     */
                    let nodeData = {
                        // 노드에 표시될 타이틀
                        label: rnode.inputValue,
                        // RDF 클래스 정보
                        rdfClassId: rnode.meta.rdfClassId,
                        rdfClassName: rnode.meta.rdfClassName,
                        // 프로퍼티 (프로퍼티에 대한 메타 정보 포함)
                        properties: rnode.properties,
                        // 부모 노드 정보
                        parentLabel: "",
                        parentClassId: "",
                        parentClassName: "",
                        hasClass: true,
                        value: 1,                        
                    };

                    this.root = this.getRoot(nodeData);
                    this.$nextTick(() => {
                        this.update(this.root);
                    });

                    this.currentNode = this.root;

                    /**
                     * 하위 노드 생성
                     */
                    for(let ix = 2; ix < nodes.length; ix++) {
                        rnode = nodes[ix];

                        nodeData = {
                            // 노드에 표시될 타이틀
                            label: rnode.inputValue,
                            // RDF 클래스 정보
                            rdfClassId: rnode.meta.rdfClassId,
                            rdfClassName: rnode.meta.rdfClassName,
                            // 프로퍼티 (프로퍼티에 대한 메타 정보 포함)
//                            properties: rnode.properties.filter(m => m.propertyType == 'class'),
                            properties: rnode.properties,
                            
                            // 부모 노드 정보
                            parentLabel: this.currentNode.data.label,
                            parentClassId: this.currentNode.data.rdfClassId,
                            parentClassName: this.currentNode.data.rdfClassName,
                            hasClass: true,
                            value: 1,                        
                        };

                        this.currentNode = this.addNode(nodeData);
                    }

                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },


        /**
         * 초기화
         */
        initDoc() {
            let link = this.container.selectAll("g.label-link");
            link.remove();
            let node = this.container.selectAll("g.node");
            node.remove();

            this.$nextTick(() => {
                this.selectedLabel = "";
                this.selectedClassName = "";
                this.showProperties = [];
                this.currentNode = null;
                this.rootNodeId = null;
                this.root = null;
            });
        },

        /**
         * 트리를 출력할 SVG 초기화
         */
        initSvg() {
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            this.width = Math.floor(clientWidth * 0.6);
            this.height = clientHeight - 72;

            let margin = { top: 10, right: 120, bottom: 10, left: 40 };
            let width = this.width;
            let dx = 30;

            /**
             * 트리의 노드 생성을 위한 생성자
             */
            this.NodeObj = d3.hierarchy.prototype.constructor;

            /**
             * SVG가 표시되는 영역의 위치와 크기를 지정한다.
             */
            this.svg = d3
                .select("svg.d3-tree2")
                .attr("viewBox", [-margin.left, -margin.top, width, dx]);

            const transform = d3.zoomIdentity
                // .translate(this.margin.left, this.margin.top)
                .translate(0, 0)
                .scale(1);

            this.container = this.svg.select("g.view-container");

            this.zoom = d3
                .zoom()
                // 스케일 범위를 지정한다. 인자 : [최소 스케일, 최대 스케일]
                .scaleExtent([1 / 4, 4])
                .on("zoom", function () {
                    d3.select("g.view-container") // SVG
                        // d3.event.transform는 마우스가 가리키는 위치에서 줌을 시작한다.
                        .attr("transform", d3.event.transform);
                });
            this.container
                .transition()
                .duration(500)
                .call(this.zoom.transform, transform);

            // 이벤트
            this.svg
                // 클릭 후 이동 (패닝)
                .call(this.zoom)
                // 더블클릭 시에 확대하는 기능을 제거, 아래 코드가 없으면 더블클릭 시에 확대 된다.
                .on("dblclick.zoom", null);
        },

        /**
         * 컴포넌트, 업무영역, 업무기능 등의 노드 추가
         */
        addNode(nodeData) {
            let parent = this.currentNode;
            const child = Object.assign(new this.NodeObj(), {
                parent,
                depth: parent.depth + 1, // 계층적 구조를 생성하는데 사용
            }); // eslint-disable-line
            let value = parseInt(Math.random() * 9999, 10) + 1;
            child.value = value;
            child.data = {
                ...nodeData,
                value: value,
                selected: false,
            };

            if (parent.children) parent.children.push(child);
            else parent.children = [child];

            this.nodes.push(child);
            this.links.push({ source: parent, target: child });

            this.update(parent);

            return child;
        },

        /**
         * 주어진 계층구조의 데이터에서 root 노드를 바환한다.
         */
        getRoot(treeData) {
            /**
             * 계층구조 데이터를 이용하여 root 노드를 생성한다.
             */
            let root = d3.hierarchy(treeData, (d) => {
                return d.children;
            });
            root.x0 = this.height / 2;
            root.y0 = 0;
            return root;
        },

        /**
         * 더블클릭으로 노드를 확장하거나 축소한다.
         */
        dblclickNode(d) {
            // Double click the node, and expand the node if there are child nodes
            if (d.children) {
                this.$set(d, "_children", d.children);
                d.children = null;
            } else {
                this.$set(d, "children", d._children);
                d._children = null;
            }
            this.$nextTick(() => {
                this.update(d);
            });
        },

        /**
         * 노트 클릭
         *
         */
        clickNode(d) {
            if (this.currentNode) {
                this.currentNode.selected = false;
            }
            console.log("@.@ CLICK =>", d);

            /**
             * class 노드가 있는 경우 확장 추가
             */
            if (d.data.hasClass == true) {
                let nlist = d.data.properties
                    .filter((m) => m.propertyType == "class")
                    .map((m) => {
                        return {
                            rdfClassName: m.propertyName,
                            resourceUri: m.resourceUri,
                            inputValue: m.inputValue,
                        };
                    });

                d.data.hasClass = false;

                rdf.getTriples(nlist).then((response) => {
                    if (response.data.returnCode == true) {
                        //console.log("@.@ TRIPLES =", response.data.result);

                        /**
                         * 현재 선택된 노드에 검색한 노드를 추가한다.
                         */
                        response.data.result.forEach((m) => {
                            /*
                             * meta에 정의한 항목이 없는 경우 추가한다.
                             */
                            m.meta.properties.filter(f=>f.propertyId !== 'rdfClassName').forEach((mp) => {
                                let ix = m.properties.findIndex(ele => ele.propertyId === mp.propertyId);
                                if (ix == -1) {
                                    let np = {
                                        propertyId: mp.propertyId,
                                        propertyName: mp.propertyName,
                                        propertyType: mp.propertyType,
                                        description: mp.description,
                                        rdfClassName: mp.rdfClassName,
                                        inputValue: "",
                                        resourceUri: "",
                                        tripleObject: "",
                                    }
                                    m.properties.push(np);
                                }
                            });

                            /**
                             * 선택된 노드에 동일한 노드가 있는지 검사한다.
                             */
                            if ( d.children !== undefined ) {
                                if ( d.children.findIndex(c => c.data.label === m.inputValue) == -1 )
                                    this.putNode(m);
                            }
                            else {
                                this.putNode(m);
                            }
                        });
                        this.$nextTick(() => {
                            this.update(d);
                        });
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch(err => {
                    errorBox(err);
                });
            }

            // this.showProperties = [];
            // this.showProperties = this.showProperties.concat(
            //     d.data.properties.filter((m) => m.propertyType != "class" && m.propertyId != "rdfClassName")
            // );
            
            // this.selectedClassName = d.data.rdfClassName;
            // this.selectedLabel = d.data.label;

            // this.nodeId = d.data.value;
            // this.nodeName = d.data.label;

            this.showDetail(d);

            d.selected = true;
            this.currentNode = d;

            this.$nextTick(() => {
                this.update(d);
            });
        },

        /**
         * 선택된 노드의 상세 정보를 출력한다.
         */
        showDetail(d) {
            this.showProperties = [];
            this.showProperties = this.showProperties.concat(
                d.data.properties.filter((m) => m.propertyType != "class" && m.propertyId != "uuid" && m.propertyId != "rdfClassName")
            );

            this.selectedClassName = d.data.rdfClassName;
            this.selectedLabel = d.data.label;

            this.nodeId = d.data.value;
            this.nodeName = d.data.label;
        },
        
        diagonal(s, d) {
            return `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;
        },
        /**
         * 루트 노드의 자식 노드와 링크를 가져온다.
         */
        getNodesAndLinks() {
            this.dTreeData = this.treemap(this.root);
            /**
             * 루트의 자식 노드 목록을 가져온다. (루트 포함)
             */
            this.nodes = this.dTreeData.descendants();
            /**
             * 노드간의 링크 정보를 가져온다.
             * - source
             * - target
             */
            this.links = this.dTreeData.descendants().slice(1);
        },

        /**
         * 트리 데이터를 DOM에 바인딩한다.
         */
        update(source) {
            /**
             * this.node - 트리 구조의 노드를 1차원 노드 배열로 저장한다.
             * this.link - 링크가 필요한 노드 정보를 1차원 배열로 저장한다.
             */
            this.getNodesAndLinks();
            //console.log("@.@ update", this.nodes, this.links);

            /**
             * node 변수에는 추가되거나 빠진 노드 정보가 있다.
             */
            let node = this.container
                .selectAll("g.node")
                .data(this.nodes, (d) => {
                    return d.id || (d.id = ++this.index);
                });

            /**
             * nodeEnter 변수는 새로 등록된 노드를 가리킨다.
             */
            let nodeEnter = node
                .enter()
                .append("g")
                .attr("class", "node") // 스타일 지정: node
                .on("dblclick", this.dblclickNode) // 노드 더블클릭
                .attr("transform", (/*d*/) => {
                    // 노드 이동
                    return "translate(" + source.y0 + "," + source.x0 + ")";
                })
                .on("click", this.clickNode); // 노드 클릭

            /**
             * 새 노드에 원을 그린다.
             */
            nodeEnter
                .append("circle")
                .attr("class", "node")
                //.attr("r", 1e-6)
                // .style("fill", function (d) {
                //     return d._children ? "#c9e4ff" : "#fff";
                // })
                // .on("mouseover",function () {return d3.select(this).style("fill","salmon").attr("r",12);})
                // .on("mouseout",function () {return d3.select(this).style("fill", "#fff").attr("r",10);})
                .append("title")
                .text(function (d) {
                    // Tooltip 텍스트 추가한다.
                    return d.data.label;
                });
            // @.@ title 밑에 있으면 동작하지 않는다.
            //
            // .on("click", function () {
            //     let self = this;
            //     setTimeout(() => {
            //         d3.select(self)
            //             .transition()
            //             .delay(1)
            //             .style("fill", function () {
            //                 return "#54a8ff";
            //             })
            //             .style("stroke-width", function () {
            //                 return "3px";
            //             });
            //     }, 100);
            // });

            /**
             * 새 노드에 텍스트를 추가한다.
             */
            nodeEnter
                .append("text")
                .attr("dy", ".35em")
                .attr("x", function (d) {
                    return d.children || d._children ? -14 : 14; // X축의 텍스트 출력 위치를 결정한다.
                })
                .attr("text-anchor", function (d) {
                    return d.children || d._children ? "end" : "start";
                })
                .attr("style", "font-size: 10px;")
                .text(function (d) {
                    return d.data.label.length > 20
                        ? d.data.label.substring(0, 20) + "..."
                        : d.data.label;
                });

            // Transition nodes to their new position.
            let nodeUpdate = nodeEnter
                .merge(node)
                .transition()
                .duration(this.duration)
                .attr("transform", function (d) {
                    return "translate(" + d.y + "," + d.x + ")";
                });

            /**
             * 추가된 원의 속성을 변경한다.
             */
            nodeUpdate
                .select("circle.node")
                .attr("r", 10) // 원의 반지름
                .style("fill", function (d) {
                    return d._children
                        ? "lightsteelblue"
                        : d.selected
                        ? "#81D4FA"
                        : "#fff"; // lightsteelblue: 노드가 축소된 경우의 색상
                })
                .style("stroke-width", function () {
                    // 원 외곽선
                    return "2px";
                })
                .attr("cursor", "pointer");

            /**
             * 추가된 노드의 테스트 투명도 조정
             */
            nodeUpdate.select("text").style("fill-opacity", 1);

            /**
             * 노드의 텍스트 속성을 변경한다.
             */
            node.select("text")
                .attr("dy", ".35em") // 텍스트의 출력위치를 조정한다
                .attr("x", function (d) {
                    return d._children ? 14 : d.children || d._children ? -14 : 14;
                })
                .attr("text-anchor", function (d) {
                    return d._children ? "start" : d.children || d._children ? "end" : "start";
                })
                .attr("style", "font-size: 10px;")
                .text(function (d) {
                    if(d._children)
                        return d.data.label.length > 20
                            ? d.data.label.substring(0, 20) + "..."
                            : d.data.label;
                    if(d.children || d._children)
                        return d.data.label.length > 6
                            ? d.data.label.substring(0, 6) + "...  "
                            : d.data.label;
                    else
                        return d.data.label.length > 20
                            ? d.data.label.substring(0, 20) + "..."
                            : d.data.label;
                });

            /**
             * 제외대상은 삭제한다.
             */
            // Transition exiting nodes to the parent's new position.
            let nodeExit = node
                .exit()
                .transition()
                .duration(this.duration)
                .attr("transform", function (d) {
                    console.log(d);
                    return "translate(" + source.y + "," + source.x + ")";
                })
                .remove();

            nodeExit.select("circle").attr("r", 1e-6);

            nodeExit.select("text").style("fill-opacity", 1e-6);

            // *************************** Links section *************************** //
            // Update the links…
            let link = this.container
                .selectAll(".label-link")
                .data(this.links, (d) => {
                    return d.id;
                });

            // Enter any new links at the parent's previous position.
            let linkG = link
                .enter()
                .insert("g", "g")
                .attr("class", "label-link");

            linkG
                .insert("path")
                // .attr("class", "link")
                .attr("fill", "none")
                .attr("stroke-width", 2)
                .attr("stroke", "#eee");
            // .attr("d", (d) => { // 노드 사이를 연결
            //     console.log("# LINK =====>", d);
            //     return this.diagonal(d, d.parent);
            // });
            linkG
                .append("text")
                //.attr("class", "link-text")
                .attr("dy", ".35em")
                .attr("style", "font-size: 9px;")
                .attr("fill", "blue")
                // .style("fill-opacity", 1)
                .attr("text-anchor", "middle")
                .text(function (d) {
                    return d.data.rdfClassName;
                })
                .call(function (selection) {
                    selection.each(function (d) {
                        d.bbox = this.getBBox();
                    });
                });

            // linkG
            //     .insert("rect","text")
            //     .attr("width", function(d){return d.bbox.width})
            //     .attr("height", function(d){return d.bbox.height})
            //     .style("fill", "yellow");

            // Transition links to their new position.
            let linkUpdate = linkG.merge(link);
            /**
             * path 위치 조정
             */
            linkUpdate
                .select("path")
                .transition()
                .duration(this.duration)
                .attr("d", (d) => {
                    return this.diagonal(d, d.parent);
                });

            /**
             * path 위의 텍스트 위치 조정
             */
            linkUpdate
                .select("text")
                .transition()
                .duration(this.duration)
                .attr("transform", function (d) {
                    return (
                        "translate(" +
                        (d.parent.y + d.y) / 2 +
                        "," +
                        (d.parent.x + d.x) / 2 +
                        ")"
                    );
                });

            // linkUpdate
            //     .select("rect")
            //     .transition()
            //     .duration(this.duration)
            //     .attr("transform", function (d) {
            //         let x = (d.parent.x + d.x) / 2 - d.bbox.width / 2;
            //         let y = (d.parent.y + d.y) / 2 - d.bbox.height / 2;
            //         return "translate(" + (y-5) + "," + (x+5) + ")";
            //     });

            // Transition exiting nodes to the parent's new position.
            link.exit()
                .transition()
                .duration(this.duration)
                .attr("d", () => {
                    //console.log("@.@ EXIT ==>", d);
                    let o = { x: source.x, y: source.y };
                    return this.diagonal(o, o);
                })
                .remove();

            // //Transition exiting link text to the parent's new position.
            // linktext.exit().transition().remove();

            // Stash the old positions for transition.
            this.nodes.forEach((d) => {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        },
    },
};
</script>
<style>
/* .d3-tree2 circle {
    fill: #fff;
    stroke: #54a8ff;
    stroke-width: 2px;
} */

.d3-tree2 circle {
    fill: #fff;
    stroke: #B0BEC5;
    stroke-width: 2px;
}

/* .d3-tree2 .node text {
    font: 12px sans-serif;
    color: red;
} */

.d3-tree2 .link {
    fill: none;
    stroke: #eee;
    stroke-width: 2px;
}

.d3-tree2 .link-text {
    font: 12px sans-serif;
    color: rgb(25, 0, 255);
}
</style>
<style scoped>
.card-container {
    padding: 4px;
    text-align: left;
}

.display-flex {
    display: flex;
}

.width-100-percent {
    width: 100%;
    height: calc(100vh - 74px);
}

.drawer-container {
    width: 350px;
    position: fixed;
    top: 110px !important;
    right: 14px !important;
    right: 0;
    z-index: 0;
    padding: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

.text-height {
    height: 48px;
    margin-top: 20px;
}

.span-title {
    font-weight: 600;
    margin-right: 20px;
}

.span-id-margin {
    margin-right: 20px;
}
</style>
