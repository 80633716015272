<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            max-width="600"
            hide-overlay
            transition="dialog-top-transition"
        >
            <v-card>
                <v-toolbar dense dark color="light-blue darken-3">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>코드 명세</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="코드번호"
                                            hide-details="auto"
                                            readonly outlined dense
                                            v-model="codeNumber"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="코드명"
                                            hide-details="auto"
                                            readonly outlined dense
                                            v-model="codeName"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-text-field
                                        label="코드설명"
                                        hide-details="auto"
                                        readonly outlined dense
                                        class="ml-3 mr-3"
                                        v-model="description"
                                    ></v-text-field>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            dense
                                            :headers="headers"
                                            :items="codeList"
                                            item-key="id"
                                            class="elevation-1 mt-0 pt-0"
                                        >
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            /**
             * 다이얼로그 제어 변수
             */
            dialog: false,

            /**
             * 테이블 정보
             */
            headers: [
                {
                    text: "코드",
                    align: "start",
                    value: "cd",
                },
                {
                    text: "코드명",
                    value: "cdNm",
                },
            ],

            codeNumber: "",
            codeName: "",
            description: "",
            codeList: [],
        };
    },

   
    methods: {
        show(cdNbr, cdNbrNm, desc, codes) {
            this.codeNumber = cdNbr;
            this.codeName = cdNbrNm;
            this.description = desc;
            this.codeList = codes;
            this.dialog = true;
        },
    },
};
</script>
