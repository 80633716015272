<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        persistent
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>편집</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text :disabled="flagModify === false"
                    @click="requestShare">변경요청</v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                filled
                                label="작성자"
                                v-model="regUser"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                filled
                                label="메모"
                                v-model="regMemo"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <Editor
                                ref="toastuiEditor"
                                :options="editorOptions"
                                :initialValue="richInputValue"
                                @change="onEditorChange"
                                height="600px"
                                initialEditType="wysiwyg"
                                previewStyle="vertical"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as rdf from "../api/rdf";
import {errorBox, messageBox } from "../utils/toast";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import umlPlugin from "@toast-ui/editor-plugin-uml";
import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import tablePlugin from "@toast-ui/editor-plugin-table-merged-cell";
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codePlugin from "@toast-ui/editor-plugin-code-syntax-highlight";
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import '@toast-ui/editor/dist/i18n/ko-kr.js';

export default {
    components: {
        Editor,
    },

    data() {
        return {
            /**
             * 등록 사용자
             */
            regUser: "",
            /**
             * 메모
             */
            regMemo: "",

            resourceUri: "",

            /**
             * 프로퍼티 편집값
             */
            richInputValue: "",
            /**
             * 다이얼로그 제어 변수
             */
            dialog: false,

            /**
             * 내용이 수정된 경우 true
             */
            flagModify: false,   

            /*
             * Toast editor 옵션
             */
            editorOptions: {
                minHeight: "200px",
                language: "ko-KR",
                useCommandShortcut: true,
                usageStatistics: true,
                hideModeSwitch: false,
                toolbarItems: [
                    ["heading", "bold", "italic", "strike"],
                    ["hr", "quote"],
                    ["ul", "ol", "task", "indent", "outdent"],
                    ["table", "image", "link"],
                    ["code", "codeblock"],
                    ["scrollSync"],
                ],
                plugins: [tablePlugin, codePlugin, colorSyntax, umlPlugin],
            },
        };
    },

    methods: {
        getHTML() {
            return this.$refs.toastuiEditor.invoke('getHTML');
        },

        getMarkdown() {
            return this.$refs.toastuiEditor.invoke("getMarkdown");
        },

        resetRichEdior() {
            return this.$refs.toastuiEditor.invoke("reset");
        },

        setRichEditor(text) {
            return this.$refs.toastuiEditor.invoke("setMarkdown", text, true);
        },

        /**
         * 편집을 시작한다.
         */
        startShare(target) {
            this.regUser = "";
            this.regMemo = "";
            this.dialog = true;
            this.resourceUri = target.data.resourceUri;
            let ix = target.data.properties.findIndex(m => m.propertyId === 'guide');
            if ( ix != -1 ) {
                this.$nextTick(() => {
                    this.setRichEditor(target.data.properties[ix].inputValue);
                    this.flagModify = false;
                });
            }
            else {
                this.$nextTick(() => {
                    this.resetRichEdior();
                    this.flagModify = false;
                });
            }
        },

        onEditorChange() {
            this.flagModify = true;
        },

        cancel() {
            this.$nextTick(() => {
                this.flagModify = false;
                this.dialog = false;
            });
        },

        /**
         * 변경된 내용을 수정 요청한다.
         */
        requestShare() {
            /**
             * 입력값 확인
             */
            if (this.regUser.length == 0) {
                messageBox("작성자를 입력하세요");
                return;
            }

            if (this.regMemo.length == 0) {
                messageBox("메모를 입력하세요");
                return;
            }

            let changeData = {
                /*
                * 변경 요청일자
                */
                reqUsr: this.regUser,
                /*
                * 변경 메모
                */
                reqMemo: this.regMemo,
                /*
                * 가이드 문서 URI
                */
                resUri: this.resourceUri,
                /*
                * 새로운 가이드
                */
                reqCont: this.getMarkdown()
            };

            rdf.requestShare(changeData)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.flagModify = false;
                        this.dialog = false;
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },
    },
};
</script>

<style></style>
