<template>
    <v-app id="sky">
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-24" max-width="420">
                            <v-card-text>
                                <v-layout justify-center align-center>
                                    <v-flex shrink>
                                        <v-img
                                            class="mt-5"
                                            src="@/assets/images/logo.png"
                                            width="222"
                                            height="32"
                                        ></v-img>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                            <v-card-text>
                                <form ref="form" @submit.prevent="login()">
                                    <v-text-field
                                        v-model="username"
                                        name="username"
                                        type="text"
                                        placeholder="Username"
                                        required
                                        outlined
                                        class="ml-4 mr-4"
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="password"
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        required
                                        outlined
                                        class="ml-4 mr-4"
                                    ></v-text-field>
                                    <v-layout justify-center align-center>
                                        <v-flex shrink>
                                            <v-btn
                                                type="submit"
                                                class="mt-1 mb-4"
                                                color="primary"
                                                :disabled="this.username.length == 0 || this.password.length == 0"
                                                >로그인</v-btn
                                            >
                                        </v-flex>
                                    </v-layout>
                                </form>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import * as users from "../api/users";
import { errorBox } from "../utils/toast";
import { mapMutations } from "vuex";

export default {
    name: "login",
    data() {
        return {
            username: "",
            password: "",
        };
    },
    methods: {
        ...mapMutations(["setAdmin", "setUserRole"]),

        /**
         * 로그인
         */
        login() {
            let loginData = {
                username: this.username,
                password: this.password,
            };

            users
                .login(loginData)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.username = "";
                        this.password = "";

                        /**
                         * store에 저장
                         */
                        this.setUserRole(response.data.result.role);

                        if (response.data.result.role == "ADMIN") {
                            this.setAdmin("Y");
                        } else {
                            this.setAdmin("N");
                        }

                        this.$router.push("/home");
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },
        oauth2() {
            window.location.href =
                "http://localhost:8080/oauth2/authorization/google";
        },
    },
};
</script>
<style>
#sky {
    background: url("../assets/images/sky2.png");
    background-size: cover;
}
</style>
