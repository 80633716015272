<template>
    <div id="app">
        <div id="wrap">
            <router-view />
        </div>
    </div>
</template>
<script>
export default {
    name: "mainPanel",
    components: {
    },
    data() {
        return {};
    },
    methods: {
    },
};
</script>

<style scoped></style>
