<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            fullscreen
            persistent
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn icon dark @click="cancel">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ targetTitle }} 편집</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="saveTriple">저장</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    filled
                                    label="라벨"
                                    v-model="labelValue"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-combobox
                                    outlined
                                    :items="properties"
                                    :item-text="(m) => m.propertyName"
                                    v-model="selectedProp"
                                    hide-details="auto"
                                    ref="comboBox"
                                >
                                    <template v-slot:item="{ index, item }">
                                        <v-text-field
                                            v-model="item.propertyName"
                                            autofocus
                                            flat
                                            background-color="transparent"
                                            hide-details
                                            solo
                                            readonly
                                        ></v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-icon :color="item.color"
                                            >mdi-format-list-checks</v-icon
                                        >
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div v-show="flagRank == false && richEdit == true">
                                    <Editor
                                        ref="toastuiEditor"
                                        :options="editorOptions"
                                        :initialValue="richInputValue"
                                        @change="onEditorChange"
                                        height="500px"
                                        initialEditType="wysiwyg"
                                        previewStyle="vertical"
                                    />
                                </div>
                                <div v-show="flagRank == false && richEdit == false && flagTable == false">
                                    <!-- <v-textarea
                                        solo
                                        auto-grow
                                        rows="2"
                                        v-model="textInputValue"
                                        hide-details="auto"
                                        @input="onTextChange"
                                    ></v-textarea> -->
                                    <v-text-field
                                        v-model="textInputValue"
                                        hide-details="auto"
                                        @input="onTextChange"
                                        solo
                                        clearable
                                    ></v-text-field>

                                </div>
                                <div v-show="flagRank == true">
                                    <v-rating
                                        v-model="ranking"
                                        background-color="indigo lighten-3"
                                        color="indigo"
                                        size="42"
                                        @input="onRankChange"
                                    ></v-rating>
                                </div>
                                <div v-show="flagTable == true">
                                    <TableSearch 
                                        v-on:changedEvent="changeTable"
                                        ref="tableSearch" />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" class="mb-0 pb-0">
                                <v-btn
                                    class="mt-0 pt-0"
                                    small
                                    color="primary"
                                    :disabled="selectedProperty === null || flagModify == false"
                                    @click="apply"
                                >
                                    적용
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { messageBox } from "../utils/toast";
import _ from "lodash";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import umlPlugin from "@toast-ui/editor-plugin-uml";
import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import tablePlugin from "@toast-ui/editor-plugin-table-merged-cell";
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codePlugin from "@toast-ui/editor-plugin-code-syntax-highlight";
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import '@toast-ui/editor/dist/i18n/ko-kr.js';
import TableSearch from "./TableSearch.vue";
import {getMeta} from "../utils/MetaManager";

export default {
    components: {
        Editor,
        TableSearch
    },

    data() {
        return {
            /**
             * 다이얼로그 타이틀
             */
            targetTitle: "",
            /**
             * 라벨 입력값
             */
            labelValue: "",
            /**
             * 등록시에 전달된 메타 데이터
             */
            meta: null,
            /**
             * 입력할 프로퍼티 목록
             * - name : 프로퍼티 이름
             * - inputValue : 입력값
             * - color : 색상, 입력여부 표시
             */
            properties: null,
            /**
             * 프로퍼티 편집값
             */
            textInputValue: "",
            richInputValue: "",
            /**
             * 다이얼로그 제어 변수
             */
            dialog: false,
            /**
             * 리스트에서 선택된 프로퍼티
             */
            selectedProperty: null,

            /**
             * 수정방법 선택
             */
            richEdit: false,
            /**
             * Rank
             */
            flagRank: false,
            ranking: 0,

            flagTable: false,

            /**
             * 내용이 수정된 경우 true
             */
            flagModify: false,   

            /*
             * Toast editor 옵션
             */
            editorOptions: {
                minHeight: "200px",
                language: "ko-KR",
                useCommandShortcut: true,
                usageStatistics: true,
                hideModeSwitch: false,
                toolbarItems: [
                    ["heading", "bold", "italic", "strike"],
                    ["hr", "quote"],
                    ["ul", "ol", "task", "indent", "outdent"],
                    ["table", "image", "link"],
                    ["code", "codeblock"],
                    ["scrollSync"],
                ],
                plugins: [tablePlugin, codePlugin, colorSyntax, umlPlugin],
            },
        };
    },

    computed: {
        selectedProp: {
            get: function() {
                return null;
            },

            set: function(value) {
                this.flagModify = false;
                this.$nextTick(() => {
                    if ( value.propertyType == 'doc' ) {
                        this.flagRank = false;
                        this.textInputValue = "";
                        this.flagTable = false;
                        this.richEdit = false;
                        this.setRichEditor(value.inputValue);
                        this.richEdit = true;
                    }
                    else if ( value.propertyType == 'table' ) {
                        this.flagRank = false;
                        this.richEdit = false;
                        this.flagTable = true;
                        this.$refs.tableSearch.setSelectedTable(value.inputValue);
                    }
                    else {
                        this.flagTable = false;
                        if ( value.propertyName == 'rank' ) {
                            if ( value.inputValue == undefined || value.inputValue.length == 0 )
                                this.ranking = 0;
                            else
                                this.ranking = parseInt(value.inputValue);
                            
                            this.flagRank = true;                            
                        }
                        else {
                            this.flagRank = false;
                            this.richEdit = false;
                            this.resetRichEdior();
                            this.textInputValue = value.inputValue;
                        }
                    }

                    this.selectedProperty = value;
                });

            }
        }
    },

    methods: {
        getHTML() {
            return this.$refs.toastuiEditor.invoke('getHTML');
        },

        getMarkdown() {
            return this.$refs.toastuiEditor.invoke("getMarkdown");
        },

        resetRichEdior() {
            return this.$refs.toastuiEditor.invoke("reset");
        },

        setRichEditor(text) {
            return this.$refs.toastuiEditor.invoke("setMarkdown", text, true);
        },

        /**
         * 테이블 선택이 변경
         */
        changeTable() {
            if ( this.flagTable )
                this.flagModify = true;
        },

        onEditorChange() {
            if ( this.richEdit )
                this.flagModify = true;
        },

        onRankChange() {
            this.flagModify = true;
        },

        onTextChange() {
            if ( this.richEdit == false )
                this.flagModify = true;
        },

        cancel() {
            this.properties = [];
            this.selectedProperty = null;
            this.$nextTick(() => {
                this.richEdit = false;
                this.flagModify = false;
                this.$refs.comboBox.internalSearch="";
                this.dialog = false;
            });
        },

        // /**
        //  * 프로퍼티 선택
        //  */
        // selectedList(e) {
        //     if (e !== undefined && typeof(e) !== 'string') {
        //         console.log("@.@ EVENT", e);
        //         this.$nextTick(() => {
        //             this.selectedProperty = e;
        //             if ( e.propertyType == 'doc' ) {
        //                 this.richEdit = true;
        //                 this.textInputValue = "";
        //                 this.setRichEditor(e.inputValue);
        //             }
        //             else {
        //                 this.richEdit = false;
        //                 this.resetRichEdior();
        //                 this.textInputValue = e.inputValue;
        //             }
        //         });
        //     }
        // },

        /**
         * 편집중인 프로퍼티 값 저장
         */
        apply() {
            if (this.selectedProperty != null) {
                if ( this.flagRank == true ) {
                    this.selectedProperty.inputValue = "" + this.ranking;
                }
                else if(this.flagTable == true) {
                    this.selectedProperty.inputValue = this.$refs.tableSearch.getSelectedtables();
                }
                else {
                    if ( this.richEdit == true ) {
                        // 편집기 입력
                        this.selectedProperty.inputValue = this.getMarkdown();
                    }
                    else {
                        // TEXT 입력
                        this.selectedProperty.inputValue = this.textInputValue;
                    }
                }

                if ( this.selectedProperty.inputValue.length == 0 )
                    this.selectedProperty.color = "#ccc";
                else
                    this.selectedProperty.color = "success";

                //this.selectedProperty = null;
                this.flagModify = false;
            }
        },

        /**
         * 디이얼로그를 오픈한다.
         */
        editTriple(target) {
            // 원본 복사
            this.orgData = _.cloneDeep(target);
            console.log("@.@ TARGET ", target, this.orgData);
            
            // 다이얼로그 타이틀
            this.targetTitle = target.rdfClassName;
            // 입력 라벨
            this.labelValue = target.label;
            // 프로퍼티
            this.properties = target.properties.filter(p => p.propertyId !== 'uuid' && p.propertyType !== 'class' && p.propertyId !== 'rdfClassName' && p.propertyId !== 'referCounter').map((p) => {
                let np = {
                    propertyId: p.propertyId,
                    propertyName: p.propertyName,
                    propertyDesc: p.description,
                    propertyType: p.propertyType,
                    color: p.inputValue.length == 0 ? "#ccc" : "success",
                    inputValue: p.inputValue,
                };
                return np;
            });

            /**
             * 
             */
            let all = getMeta(target.rdfClassName).properties.filter(p => p.propertyId !== 'uuid' && p.propertyType !== 'class' && p.propertyId !== 'rdfClassName' && p.propertyId !== 'referCounter' );
            
            let add = all.filter(e => {
                let ix = this.properties.findIndex(m=>m.propertyId === e.propertyId);
                return ix === -1;
            });

            add.forEach(p => {
                let np = {
                    propertyId: p.propertyId,
                    propertyName: p.propertyName,
                    propertyDesc: p.description,
                    propertyType: p.propertyType,
                    color: "#ccc",
                    inputValue: ""
                };
                this.properties.push(np);
                this.orgData.properties.push(_.cloneDeep(np));
            });

            console.log("@.@ AAAA",this.properties);

            this.ranking = 0;
            this.selectedProperty = null;
            
            this.$nextTick(() => {
                this.resetRichEdior();
            });
            this.dialog = true;
        },

        /**
         * 저장 버튼 클릭
         */
        saveTriple() {
            if (this.labelValue.length == 0) {
                messageBox("라벨을 입력하세요");
                return;
            }
            /**
             * 입력된 값을 반환한다.
             */
            let changeData = {
                subject: {
                    oldResourceUri: this.orgData.resourceUri,
                    newValue: this.labelValue,
                    rdfClassName: this.orgData.rdfClassName,
                    rdfClassId: this.orgData.rdfClassId
                },
                changeSubject: this.labelValue !== this.orgData.label,
            };

            // console.log("@.@ VALUES = ", this.orgData, changeData);

            /**
             * 라벨이 변경되었는지 검사한다.
             */
            if ( this.labelValue !== this.orgData.label ) {
                // console.log("@.@ 라벨 변경됨!!!", this.labelValue + "=>" + this.orgData.resourceUri +
                //              "|" + this.orgData.rdfClassName + "|" + this.orgData.rdfClassId);
                changeData.changeSubject = true;
            }

            /**
             * 속성에서 변경된 값을 추출한다.
             */
            let props = [];
            changeData.properties = props;

            this.properties.forEach( p => {
                let ix = this.orgData.properties.findIndex(e => e.propertyId == p.propertyId);
                if ( ix != -1 ) {
                    if ( p.inputValue !== this.orgData.properties[ix].inputValue ) {
                        // console.log("@.@ 프로퍼티 변경!!! " + p.propertyName + "|OLD=" + this.orgData.properties[ix].inputValue + 
                        //             ", NEW=" + p.inputValue);
                        let pv = {
                            propertyName: p.propertyName,
                            propertyId: p.propertyId,
                            propertyType: p.propertyType,
                            oldValue: this.orgData.properties[ix].inputValue,
                            newValue: p.inputValue
                        };
                        props.push(pv);
                    }
                }
            });

            this.$emit("saveEvent", changeData);

            this.richEdit = false;
            this.flagModify = false;
            this.$refs.comboBox.internalSearch="";
            this.dialog = false;
        },
    },
};
</script>

<style></style>
