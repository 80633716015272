<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t("TableMethodSelector.t01")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            :disabled="selected.length == 0"
                            @click="selectTable"
                            >{{$t("TableMethodSelector.t02")}}</v-btn
                        >
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row dense>
                            <v-col cols="12">
                                <v-card>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="2">
                                                <v-radio-group
                                                    v-model="autoType"
                                                    row
                                                    hide-details="auto"
                                                    class="mt-0 pt-0"
                                                >
                                                    <v-radio
                                                        label="ID"
                                                        value="I"
                                                    ></v-radio>
                                                    <v-radio
                                                        label="NAME"
                                                        value="N"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col cols="10">
                                                <v-autocomplete
                                                    class="mt-0 pt-0"
                                                    v-model="selectedTable"
                                                    :items="searchedTables"
                                                    :loading="isLoading"
                                                    :search-input.sync="search"
                                                    hide-no-data
                                                    hide-selected
                                                    clearable
                                                    :item-text="itemText"
                                                    :item-value="itemValue"
                                                    :placeholder="placeholderText"
                                                    prepend-icon="mdi-database-search"
                                                    :return-object="true"
                                                    hide-details="auto"
                                                >
                                                    <template
                                                        v-slot:item="data"
                                                    >
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-text="data.item.tableName"
                                                            ></v-list-item-title>
                                                            <v-list-item-subtitle
                                                                v-text="data.item.tableId"
                                                            ></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    v-model="selected"
                                    :headers="headers"
                                    :items="searchResult"
                                    :single-select="true"
                                    item-key="id"
                                    show-select
                                    class="elevation-1"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="justify-start ml-4 mt-0 pt-0">
                    <v-btn
                        text
                        outlined
                        :disabled="selected.length == 0"
                        @click="selectTable"
                        >{{$t("TableMethodSelector.t02")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { messageBox, errorBox } from "../utils/toast";
import * as rdf from "../api/rdf";
import axios from "axios";

export default {
    data() {
        return {
            selectedTable: null,
            search: null,
            searchedTables: [],
            autoType: "N",

            headers: [
                {
                    text: "L1",
                    align: "start",
                    value: "l1Name",
                },
                {
                    text: "L2",
                    value: "l2Name",
                },
                {
                    text: this.$t("TableMethodSelector.t03"),
                    value: "className",
                },
                {
                    text: this.$t("TableMethodSelector.t04"),
                    value: "methodName",
                },
            ],

            searchResult: [],
            selected: [],
            loading: false,
            isLoading: false,
            dialog: false,
        };
    },

    computed: {
        itemText() {
            return this.autoType === 'I' ? "tableId" : "tableName";
        },

        itemValue() {
            return this.autoType === 'I' ? "tableName" : "tableId";
        },

        placeholderText() {
            return this.autoType === 'I' ? this.$t("TableMethodSelector.t05") : this.$t("TableMethodSelector.t06");
        }
    },

    watch: {
        selectedTable() {
            this.searchTable();
        },

        /**
         * Autocomplete 데이터 조회 
         */
        search(val) {
            if (this.isLoading) return;
            if (val == null || val.length == 0) return;

            this.isLoading = true;

            let ac = axios.create({	timeout: 30000 });
            let locale = process.env.VUE_APP_I18N_LOCALE || 'ko';
            ac.get(`/v1/tables/auto/${locale}/${val}`)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.searchedTables = response.data.result;
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                })
                .finally(() => (this.isLoading = false));
        },
    },

    methods: {
        /**
         * 자바소스 검색을 시작한다.
         */
        beginSearch() {
            this.selectedTable = null;
            this.searchResult = [];
            this.selected = [];
            this.searchedTables = [];
            this.dialog = true;
        },

        /**
         * 선택 버튼 클릭
         */
        selectTable() {
            this.$emit("selectEvent", this.selected);
            this.dialog = false;
        },

        /**
         * 테이블 검색
         */
        searchTable() {
            if (this.selectedTable == null || this.selectedTable.tableId.length == 0) {
                messageBox(this.$t("TableMethodSelector.t07"));
                return;
            }

            this.loading = true;
            this.selected = [];

            rdf.searchTable(this.selectedTable.tableId)
                .then((response) => {
                    this.loading = false;

                    if (response.data.returnCode == true) {
                        let id = 1;
                        this.searchResult = response.data.result.map((m) => {
                            return {
                                ...m,
                                subject: m.classNameUri,
                                id: id++,
                            };
                        });
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },
    },
};
</script>

<style></style>
