import net from "@/utils/net.js";

/**
 *
 * @param {*} resUri 
 * @param {*} reqDtm 
 * @returns 
 */
export function viewDoc(resUri, reqDtm) {
	return net.get(`/v1/shares/${resUri}/${reqDtm}`);
}

/**
 * 지정된 문서를 복원한다.
 * @param {*} resUri 
 * @param {*} reqDtm 
 * @returns 
 */
export function rollback(resUri, reqDtm) {
	return net.put(`/v1/shares/rollback/${resUri}/${reqDtm}`);
}

/**
 * 지정된 요청을 반려한다.
 * @param {*} resUri 
 * @param {*} reqDtm 
 * @param {*} cmt 
 * @returns 
 */
export function reject(resUri, reqDtm, cmt) {
	let payload = {
		reqDtm: reqDtm,
		resUri: resUri,
		reason: cmt
	};
	
	return net.put(`/v1/shares`, payload);
}

/**
 * 지정된 요청을 반영한다.
 * @param {*} resUri 
 * @param {*} reqDtm 
 * @returns 
 */
export function apply(resUri, reqDtm) {
	return net.put(`/v1/shares/${resUri}/${reqDtm}`);
}

/**
 * 지정된 리소스의 변경 이력을 가져온다.
 * @param {*} resUri 
 * @returns 
 */
export function selectRequestList(resUri) {
	return net.get(`/v1/shares/${resUri}`);
}

/**
 * 변경된 가이드 문서를 반영 요청한다.
 * @param {*} shareData 
 * @returns 
 */
export function requestShare(shareData) {
	return net.post(`/v1/shares`, shareData);
}

/**
 * 
 * @param {*} resourceUri 
 * @param {*} newRank 
 * @returns 
 */
export function changeRank(resourceUri, newRank) {
	return net.put(`/v1/knowledges/rank/${resourceUri}/${newRank}`);
}

/**
 * 지정된 자원의 코멘트를 가져온다.
 * @param {*} resourceUri 
 * @returns 
 */
export function getComment(resourceUri) {
	return net.get(`/v1/knowledges/comments/${resourceUri}`);
}

/**
 * 지정된 코멘트를 저장한다.
 * @param {*} commentObject 
 * @returns 
 */
export function saveComment(commentObject) {
	return net.post(`/v1/knowledges/comments`, commentObject);
}

/**
 * 지정된 테이블을 사용하는 메소드(클래스,L1,L2) 검색
 * @param {*} tableName 
 * @returns 
 */
export function searchTable(tableName) {
	return net.get(`/v1/triples/tables/${tableName}`);
}

/**
 * 특정 업무 기능을 구현한 자바 소스 전체를 추가한다.
 * @param {*} bulkData 
 * @returns 
 */
export function addJavaSingle(targetData) {
	return net.post(`/v1/knowledges/java/single`, targetData);
}

/**
 * 특정 업무 기능을 구현한 자바 소스 전체를 추가한다.
 * @param {*} bulkData 
 * @returns 
 */
 export function addJavaBulk(targetData) {
	return net.post(`/v1/knowledges/java/bulk`, targetData);
}

/**
 * RDF 정보를 수정한다.
 * 
 * @param {*} changeData 
 * @returns 
 */
export function updateTriple(changeData) {
	return net.put(`/v1/knowledges`, changeData);
}

/**
 * 지정된 subject에 UUID를 갖는 노드를 삭제한다.
 * @param {*} subject 
 * @param {*} predicate 
 * @returns 
 */
export function deleteNode(subject, predicate) {
	return net.delete(`/v1/knowledges/${subject}/${predicate}`);
}

/**
 * 지정된 subject의 상위 노드를 가져온다.
 * @param {*} subject 
 */
export function loadTree(subject) {
	return net.get(`/v1/apis/${subject}`);
}

/**
 * 자바소스로 생성한 JavaDoc RDF를 검색한다.
 * @param {*} funcName 업무기능
 * @param {*} searchType 검색구분(C:클래스, M:메소드)
 * @param {*} targetValue 검색 입력값
 * @param {*} docString JavaDoc 문자열
 * @returns 
 */
export function searchApi(funcName, searchType, targetValue, docString) {
	let url = `/v1/apis?funcName=${funcName}&searchType=${searchType}&targetValue=${targetValue}&docString=${docString}`;
	return net.get(url);
}

/**
 * 지정된 subject의 정보를 가져온다.
 * @param {*} classList 
 * @returns 
 */
export function getTriples(classList) {
	return net.post(`/v1/triples`, classList);
}

/**
 * 루트에 연결된 노드를 가져온다.
 * @param {*} className 
 * @returns 
 */
export function getRoot(className) {
	return net.get(`/v1/triples/root/${className}`);
}

/**
 * 지정된 subject의 정보를 가져온다.
 * @param {*} subject 
 * @returns 
 */
export function getTriple(subject) {
	return net.get(`/v1/triples/${subject}`);
}

/**
 * 지정된 소스의 RDF 정보를 가져온다.
 * @param {*} fileUri 
 */
export function getSource(fileUri) {
	return net.get(`/v1/javadocs/${fileUri}`);
}

/**
 * 자바소스로 생성한 JavaDoc RDF를 검색한다.
 * @param {*} funcName 
 * @param {*} fileName 
 * @param {*} docString 
 * @returns 
 */
export function searchSource(funcName, fileName, docString) {
	let url = `/v1/javadocs?funcName=${funcName}&fileName=${fileName}&docString=${docString}`;
	return net.get(url);
}


/**
 * 지식 데이터를 저장한다.
 * @param {*} nodeObject 
 * @returns 
 */
export function saveKnowledge(nodeObject) {
	//console.log("@.@ saveKnowledge =>", nodeObject);
	return net.post(`/v1/knowledges`, nodeObject);
}
