<template>
    <v-dialog
        transition="dialog-top-transition"
        max-width="800"
        v-model="dialog"
    >
        <v-responsive class="overflow-y-hidden fill-height" height="600">
            <v-card flat class="d-flex flex-column fill-height">
                <v-toolbar dense dark color="primary" max-height="48px">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>COMMENT</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <!-- <v-btn dark text @click="dialog = false">닫기</v-btn> -->
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="flex-grow-1 overflow-y-auto">
                    <template v-for="(msg, i) in messages">
                        <div
                            :key="i"
                        >
                        <!--
                            :class="{ 'd-flex flex-row-reverse': msg.me }"
                        -->
                            <v-chip
                                color="primary"
                                outlined
                                style="height: auto; white-space: normal"
                                class="pa-2 mb-1"
                            >
                                <span class="wrapclass">
                                    {{ msg.comment }}
                                </span>
                                <sub class="ml-1 mr-0 pr-0 d-flex flex-row-reverse: true" style="font-size: 0.5rem; color: #000000">{{
                                    msg.createAt
                                }}</sub>
                            </v-chip>
                        </div>
                    </template>
                </v-card-text>
                <v-card-text class="flex-shrink-1">
                    <v-text-field
                        v-model="inputText"
                        label="Type a comment"
                        type="text"
                        no-details
                        outlined
                        append-outer-icon="mdi-send"
                        @keyup.enter="saveComment"
                        @click:append-outer="saveComment"
                        hide-details
                    />
                </v-card-text>
            </v-card>
        </v-responsive>
    </v-dialog>
</template>

<script>
import * as rdf from "../api/rdf";
import {errorBox /*, messageBox */} from "../utils/toast";

export default {
    data: () => {
        return {
            dialog: false,
            inputText: "",
            resourceUri: null,
            messages: [],
            targetNode: null
        };
    },

    methods: {
        /**
         * 코멘트 창 열기
         */
        showComment(target) {
            this.targetNode = target;
            this.inputText = "";
            this.messages = [];

            //console.log("@.@ TARGET : ", target.data.resourceUri);
            this.resourceUri = target.data.resourceUri;
            rdf.getComment(this.resourceUri)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.messages = response.data.result;
                        this.dialog = true;
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * 입력 코멘트 저장
         */
        saveComment() {
            if (this.inputText.length == 0) return;

            let cmtObject = {
                resourceUri: this.resourceUri,
                comment: this.inputText
            };

            rdf.saveComment(cmtObject)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.inputText = "";
                        this.messages.unshift(response.data.result);
                        let ix = this.targetNode.data.properties.findIndex(m => m.propertyId === 'comment');
                        if ( ix != -1) {
                            if(this.targetNode.data.properties[ix].inputValue.length == 0) {
                                this.targetNode.data.properties[ix].inputValue = response.data.result.createAt + "#" + response.data.result.comment;
                            }
                            else {
                                this.targetNode.data.properties[ix].inputValue = this.targetNode.data.properties[ix].inputValue +
                                    "##" + response.data.result.createAt + "#" + response.data.result.comment;
                            }
                        }
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapclass {
    max-width: 85%;
    overflow: auto;
    //  text-overflow: ellipsis;
}

// div > ::v-deep .en {
//     background: rgb(243, 243, 243);
//     color: blue;
//     margin-bottom: 5px;
//     width: 100%;
// }

// div > ::v-deep .ko {
//     background: rgb(243, 243, 243);
//     color: blue;
//     margin-bottom: 8px;
// }

// ::v-deep pre {
//     overflow-x: auto;
//     white-space: pre-wrap;
//     white-space: -moz-pre-wrap;
//     white-space: -pre-wrap;
//     white-space: -o-pre-wrap;
//     word-wrap: break-word;
// }
</style>
