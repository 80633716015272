import net from "@/utils/net.js";

/**
 * 지정된 코드의 인스턴스 정보를 가져온다.
 * @param {*} cdNbr 
 * @returns 
 */
export function getInstances(cdNbr) {
	return net.get(`/v1/tables/code/${cdNbr}`);
}


/**
 * ERD 다이어그램 생성
 * @param {*} tableId 
 * @returns 
 */
export function getERD(tableId) {
	return net.get(`/v1/tables/relations/${tableId}/false`);
}

/**
 * 테이블 검색 자동완성
 * @param {*} table 
 * @returns 
 */
export function autocomplte(table) {
	let locale = process.env.VUE_APP_I18N_LOCALE || 'ko';
	return net.get(`/v1/tables/auto/${locale}/${table}`);
}

/**
 * 
 * @param {*} tables 
 * @returns 
 */
export function getTable(tables) {
	let locale = process.env.VUE_APP_I18N_LOCALE || 'ko';
	return net.get(`/v1/tables/${locale}/${tables}`);
}

/**
 * 
 * @param {*} id 
 * @param {*} name 
 * @returns 
 */
export function searchTable(id, name) {
	return net.get(`/v1/tables?id=${id}&name=${name}`);
}
