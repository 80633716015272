<template>
    <v-dialog
        v-model="dialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>테이블 보기</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <!-- <v-btn
                        dark
                        text
                        @click="dialog = false"
                        >닫기</v-btn
                    > -->
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <Tableview ref="tableView"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Tableview from "./TableView.vue";
import * as tab from "../api/table";
import {/*messageBox,*/ errorBox} from "../utils/toast";

export default {
    components: {
        Tableview,
    },
    data: () => {
        return {
            dialog: false,
        };
    },

    methods: {
        showTable(tables) {
            // console.log(tables);
            tab.getTable(tables).then(response => {
                if ( response.data.returnCode == true ) {
                    this.dialog = true;
                    this.$nextTick(() => {
                        this.$refs.tableView.showTable(response.data.result);
                    });
                }
                else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },
    },
};
</script>
