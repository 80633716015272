import net from "@/utils/net.js";

/**
 * 시퀀스 다이어그램
 * 
 * @param {*} classMethod 
 * @returns 
 */
export function loadSequenceDiagram(classMethod) {
	return net.get(`/v1/uml/sequence/${classMethod}`);
}

/**
 * 
 * @param {*} className 
 * @returns 
 */
export function loadClassDiagram(className) {
	return net.get(`/v1/uml/rdf/${className}`);
}
