<template>
    <v-app>
        <v-app-bar app color="#1A237E" dense dark>
            <v-tabs
                background-color="#1A237E"
                center-active
                dark
                v-model="selectedMenu"
            >
                <v-tab key="rdfClasses" v-if="isAdmin">클래스 목록</v-tab>
                <v-tab key="kgInput" v-if="isAdmin">도메인 지식 등록</v-tab>
                <v-tab key="admin" v-if="isAdmin">관리자</v-tab>
                <v-tab key="kgSearch">도메인 지식 검색</v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <!-- feedback -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon class="mr-5"
                        @click="accessLog"
                        v-on="on"
                    >mdi-chart-areaspline</v-icon>
                </template>
                <span>접속현황</span>
            </v-tooltip>


            <!-- feedback -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-icon class="mr-5"
                        @click="feedback"
                        v-on="on"
                    >mdi-comment-plus-outline</v-icon>
                </template>
                <span>피드백</span>
            </v-tooltip>

            <!-- login, logout -->
            <v-icon class="mr-5"
                @click="logout"
            >{{iconName}}</v-icon>
            <v-img
                alt="Bankware Global"
                class="shrink mr-2"
                contain
                src="@/assets/images/logo2.png"
                transition="scale-transition"
                width="128"
            />
        </v-app-bar>
        <v-main>
            <v-tabs-items v-model="selectedMenu">
                <v-tab-item key="rdfClasses"  v-if="isAdmin">
                    <ClassLIstView />
                </v-tab-item>
                <v-tab-item key="kgInput"  v-if="isAdmin">
                    <KnowledgeInputView />
                </v-tab-item>
                <v-tab-item key="admin"  v-if="isAdmin">
                    <AdminView />
                </v-tab-item>
                <v-tab-item key="kgSearch">
                    <KnowledgeSearchView />
                </v-tab-item>
            </v-tabs-items>
        </v-main>
         <!-- 로그인 -->
        <LoginDialog ref="login" />
        <!-- Access Log -->
        <AccessLogChart ref="access" />
        <!-- Feedback -->
        <FeedbackDialog ref="feedback" />
    </v-app>
</template>

<script>
import ClassLIstView from "./views/ClassLIstView.vue";
import KnowledgeInputView from "./views/KnowledgeInputView";
import KnowledgeSearchView from "./views/KnowledgeSearchView.vue";
import AdminView from "./views/AdminView.vue";
import LoginDialog from "./components/LoginDialog.vue";
import AccessLogChart from "./components/AccessLogChart.vue";
import FeedbackDialog from "./components/FeedbackDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import * as users from "./api/users";
import {errorBox} from "./utils/toast";

/*
 * Theme designer
 * https://lobotuerto.com/vuetify-color-theme-builder/
 */
export default {
    name: "App",

    components: {
        ClassLIstView,
        KnowledgeInputView,
        KnowledgeSearchView,
        LoginDialog,
        AdminView,
        AccessLogChart,
        FeedbackDialog
    },

    data() {
        return {
            selectedMenu: null,
            flagLogin: false,
        };
    },

    computed: {
        ...mapGetters(["isAdmin", "isNone"]),

        iconName() {
            return this.isAdmin == true ? "mdi-logout" : "mdi-key";
        }
    },

    methods: {
        ...mapMutations(['setAdmin', 'setUserRole']),

        /**
         * 
         */
        logout() {
            if(this.isNone == false ) {
                if(this.isAdmin == true) {
                    users.logout().then(response => {
                        if (response.data.returnCode == true) {
                            this.setAdmin("N");
                            this.setUserRole("");
                            this.$router.push('/');
                        } else {
                            errorBox(response.data.returnMessage);
                        }
                    })
                    .catch(err => {
                        errorBox(err);
                    });
                }
            }
            else {
                if(this.isAdmin == false) {
                    this.$nextTick(() => {
                        this.$refs.login.login();
                    });
                }
                else {
                    this.setAdmin("N");
                }
            }
        },

        /**
         * 접속 차트
         */
        accessLog() {
            this.$refs.access.showAccessChart();
        },

        /**
         * FEEDBACK
         */
        feedback() {
            this.$refs.feedback.showFeedback();
        }
    },
};
</script>
