import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
    state: {
        flagAdmin: "N",
        userRole: "",
        flagNone: true
    },
    mutations: {
        /**
         * 관리자 설정
         * @param {*} state 
         * @param {*} flag 
         */
        setAdmin(state, flag) {
            state.flagAdmin = flag;
        },

        setUserRole(state, role) {
            state.userRole = role;
        },

        setNone(state, none) {
            state.flagNone = none;
        },
    },
    getters: {
        /**
         * 초기 로그인 여부
         * @param {*} state 
         * @returns 
         */
        isNone(state) {
            return state.flagNone;
        },

        /**
         * 관리자 여부
         * @param {*} state
         * @returns
         */
        isAdmin(state) {
            return state.flagAdmin == "Y";
        },

        /**
         * @param {*} state 
         * @returns 
         */
        getUserRole(state) {
            return state.userRole;
        }
    },
    actions: {},
    modules: {},
});
