<template>
    <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>수정이력</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <!-- <v-btn
                        dark
                        text
                        @click="dialog = false"
                        >닫기</v-btn
                    > -->
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-row dense>
                        <v-col cols="12">
                            <v-data-table
                                dense
                                v-model="selected"
                                :headers="headers"
                                :items="searchResult"
                                :single-select="true"
                                item-key="id"
                                show-select
                                class="elevation-1"
                            >
                            </v-data-table>
                            <div class="text-left pt-3">
                                <v-btn
                                    color="primary"
                                    class="mr-2"
                                    @click="apply"
                                    :disabled="checkApply"
                                >
                                    적용
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    class="mr-2"
                                    @click="reject"
                                    :disabled="checkReject"
                                >
                                    반려
                                </v-btn>

                                <v-btn
                                    color="error"
                                    class="mr-2"
                                    @click="rollback"
                                    :disabled="checkRollback"
                                >
                                    복원
                                </v-btn>

                                <v-btn
                                    color="primary"
                                    @click="viewDoc"
                                    :disabled="this.selected.length == 0"
                                >
                                    보기
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <!-- 실행 여부 확인 -->
        <v-dialog v-model="confirmDialog" max-width="400">
            <v-card>
                <v-card-title class="headline">{{confirmTitle}}</v-card-title>
                <v-card-text class="subtitle-1 text-align-left">
                    {{confirmMessage}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="confirmDialog = false">취소</v-btn>
                    <v-btn color="red" text @click="execute()"
                        >실행</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- 반려 사유 입력 -->
        <v-dialog v-model="rejectDialog" max-width="400">
            <v-card>
                <v-card-title class="headline">{{confirmTitle}}</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="reason"
                        :label="confirmMessage"
                        hide-details="auto"
                    ></v-text-field>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="rejectDialog = false">취소</v-btn>
                    <v-btn color="red" text
                        :disabled="reason.length == 0"
                        @click="execute()"
                        >실행</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- markdown 문서 열람 -->
        <DocViewer ref="docViewer" />
    </v-dialog>
</template>

<script>
import { messageBox, errorBox } from "../utils/toast";
import * as rdf from "../api/rdf";
import { mapGetters } from "vuex";
import DocViewer from "./DocViewer.vue";

export default {
    components: {
        DocViewer
    },

    data() {
        return {
            headers: [
                {
                    text: "요청일",
                    align: "start",
                    value: "reqDtm",
                },
                {
                    text: "요청자",
                    value: "reqUsr",
                },
                {
                    text: "메모",
                    value: "reqMemo",
                },
                {
                    text: "적용일",
                    value: "accDtm",
                },
                {
                    text: "반려일",
                    value: "rejDtm",
                },
                {
                    text: "반려사유",
                    value: "rejReas",
                },
            ],

            searchResult: [],
            selected: [],
            dialog: false,

            rejectDialog: false,
            reason: "",
            confirmDialog: false,
            confirmTitle: "",
            confirmMessage: "",
            executeType: 1,  /* 1 - 적용, 2 - 반려, 3 - 복원 */

            availApply: false,
            availReject: false,
            availRollback: false,
        };
    },

    watch: {
        selected() {
            this.availApply = false;
            this.availReject = false;
            this.availRollback = false;

            if ( this.selected.length > 0 ) {
                if ( this.selected[0].accDtm !== null) {
                    this.availRollback = true;
                }

                if ( this.selected[0].accDtm === null && this.selected[0].rejDtm === null ) {
                    this.availReject = true;
                    this.availApply = true;
                }
            }
        }
    },

    computed: {
        ...mapGetters(["isAdmin"]),

        checkApply() {
            return !this.isAdmin || !this.availApply;
        },

        checkReject() {
            return !this.isAdmin || !this.availReject;
        },

        checkRollback() {
            return !this.isAdmin || !this.availRollback;
        },
    },

    methods: {
        /**
         * 변경이력을 조회한다.
         */
        showHistory(resUri) {
            this.searchResult = [];
            this.selected = [];
            this.reason = "";

            rdf.selectRequestList(resUri)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        let id = 1;
                        this.searchResult = response.data.result.map((m) => {
                            return {
                                ...m,
                                id: id++,
                            };
                        });
                        this.dialog = true;
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * 변경 반영
         */
        apply() {
            this.confirmTitle = "반영";
            this.confirmMessage = "선택된 변경을 반영할까요?";
            this.confirmDialog = true;
            this.executeType = 1;
        },

        /**
         * 반려
         */
        reject() {
            this.confirmTitle = "반려";
            this.confirmMessage = "반려사유를 입력하세요";
            this.executeType = 2;
            this.rejectDialog = true;
        },

        /**
         * 복원
         */
        rollback() {
            this.confirmTitle = "복원";
            this.confirmMessage = "선택된 변경을 복원할까요?";
            this.confirmDialog = true;
            this.executeType = 3;
        },

        /**
         * 선택된 작업을 수행한다.
         */
        execute() {
            if(this.executeType == 1) { // 적용
                rdf.apply(this.selected[0].resUri, this.selected[0].reqDtm)
                    .then((response) => {
                        if (response.data.returnCode == true) {
                            this.selected[0].accDtm = response.data.result.reqDtm;
                            this.availApply = false;
                            this.availReject = false;
                            this.availRollback = true;
                            this.confirmDialog = false;
                        } else {
                            errorBox(response.data.returnMessage);
                        }
                    })
                    .catch((err) => {
                        errorBox(err);
                    });
            }
            else if(this.executeType == 2) {    // 반려
                rdf.reject(this.selected[0].resUri, this.selected[0].reqDtm, this.reason)
                    .then((response) => {
                        if (response.data.returnCode == true) {
                            this.selected[0].rejDtm = response.data.result.reqDtm;
                            this.selected[0].rejReas = this.reason;
                            this.availApply = false;
                            this.availReject = false;
                            this.availRollback = false;
                            this.rejectDialog = false;
                        } else {
                            errorBox(response.data.returnMessage);
                        }
                    })
                    .catch((err) => {
                        errorBox(err);
                    });            
            }
            else if(this.executeType == 3) {    // 복원
                rdf.rollback(this.selected[0].resUri, this.selected[0].reqDtm)
                    .then((response) => {
                        if (response.data.returnCode == true) {
                            messageBox("완료되었습니다.");
                            this.confirmDialog = false;
                        } else {
                            errorBox(response.data.returnMessage);
                        }
                    })
                    .catch((err) => {
                        errorBox(err);
                    });   
            }

        },

        /**
         * 편집 문서 보기
         */
        viewDoc() {
            rdf.viewDoc(this.selected[0].resUri, this.selected[0].reqDtm)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.$refs.docViewer.showDocument(response.data.result);
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        }
    },
};
</script>

<style></style>
