import net from "@/utils/net.js";

/**
 * 상품조건을 검색한다.
 * @param {*} inputData 
 * @returns 
 */
export function searchCondition(inputData) {
	return net.post(`/v1/pfc`, inputData);
}
