<template>
    <v-container fluid>
        <v-expansion-panels>
            <!-- 사용자 관리 -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span style="color:blue; font-weight:bold">사용자 관리</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <UserList />
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- UML 메타 정보 -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <span style="color:blue; font-weight:bold">UML 메타 정보</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12">
                            <v-card class="mx-auto pa-0">
                                <!-- <v-toolbar color="#1E88E5" dark dense>
                                    <v-toolbar-title
                                        >UML 메타정보</v-toolbar-title
                                    >
                                </v-toolbar> -->

                                <v-card-text>
                                    <v-file-input
                                        accept="application/json"
                                        label="UML 메타파일"
                                        v-model="umlFile"
                                    ></v-file-input>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        outlined
                                        text
                                        color="teal accent-4"
                                        @click="importUML"
                                    >
                                        입력
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- 모델반입 -->
            <v-expansion-panel>
                <v-expansion-panel-header><span style="color:blue; font-weight:bold">모델 반입</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12">
                            <v-card class="mx-auto pa-0">
                                <!-- <v-toolbar color="#1E88E5" dark dense>
                                    <v-toolbar-title>모델 반입</v-toolbar-title>
                                </v-toolbar> -->

                                <v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-file-document"
                                        v-model="importModelName"
                                        label="모델명"
                                        type="text"
                                    ></v-text-field>
                                    <v-file-input
                                        accept="application/xml"
                                        label="모델파일"
                                        v-model="file"
                                    ></v-file-input>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        outlined
                                        text
                                        color="teal accent-4"
                                        @click="importModel"
                                    >
                                        반입
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- 모델 반출 -->
            <v-expansion-panel>
                <v-expansion-panel-header><span style="color:blue; font-weight:bold">모델 반출</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12">
                            <v-card class="mx-auto pa-0">
                                <!-- <v-toolbar color="#1E88E5" dark dense>
                                    <v-toolbar-title>모델 반출</v-toolbar-title>
                                </v-toolbar> -->
                                <v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-file-document"
                                        v-model="exportModelName"
                                        label="모델명"
                                        type="text"
                                    ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        outlined
                                        text
                                        color="teal accent-4"
                                        @click="exportModel"
                                    >
                                        반출
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- 문서등록 -->
            <v-expansion-panel>
                <v-expansion-panel-header><span style="color:blue; font-weight:bold">문서 등록</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12">
                            <v-card class="mx-auto pa-0">
                                <!-- <v-toolbar color="#1E88E5" dark dense>
                                    <v-toolbar-title>문서 등록</v-toolbar-title>
                                </v-toolbar> -->

                                <v-card-text>
                                    <v-combobox
                                        outlined
                                        :items="l1List"
                                        :item-text="(m) => m.componentName"
                                        v-model="selectedL1"
                                        label="컴포넌트"
                                    >
                                    </v-combobox>
                                    <v-text-field
                                        prepend-icon="mdi-file-document"
                                        v-model="documentModelName"
                                        label="모델명"
                                        type="text"
                                    ></v-text-field>
                                    <v-file-input
                                        label="문서파일"
                                        v-model="docFile"
                                    ></v-file-input>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        outlined
                                        text
                                        color="teal accent-4"
                                        @click="saveDocument"
                                    >
                                        저장
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- 모델반입 -->
            <v-expansion-panel>
                <v-expansion-panel-header><span style="color:blue; font-weight:bold">ERD 관계</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12">
                            <v-card class="mx-auto pa-0">
                                <v-card-text>
                                    <v-file-input
                                        accept="text/html"
                                        label="ERD 관계 HTML"
                                        v-model="erdFile"
                                    ></v-file-input>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        outlined
                                        text
                                        color="teal accent-4"
                                        @click="importERD"
                                    >
                                        관계정보 입력
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- 공통코드 인스턴스 반입 -->
            <v-expansion-panel>
                <v-expansion-panel-header><span style="color:blue; font-weight:bold">공통 코드</span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12">
                            <v-card class="mx-auto pa-0">
                                <v-card-text>
                                    <v-file-input
                                        accept="application/json"
                                        label="코드기본"
                                        v-model="cdmFile"
                                    ></v-file-input>
                                    <v-file-input
                                        accept="application/json"
                                        label="코드명세"
                                        v-model="cddFile"
                                    ></v-file-input>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn
                                        outlined
                                        text
                                        color="teal accent-4"
                                        @click="importComCode"
                                    >
                                        코드정보 입력
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
import * as admin from "../api/admin";
import { errorBox, messageBox } from "../utils/toast";
import FileDownload from "js-file-download";
import UserList from "../components/UserLIst.vue";

/**
 * ICON:
 * https://pictogrammers.github.io/@mdi/font/2.0.46/
 */
export default {
    data: () => ({
        exportModelName: "",
        importModelName: "",
        file: null,
        /**
         * 문서저장
         */
        documentModelName: "",
        l1List: [],
        targetUUID: "",
        docFile: null,
        /**
         * UML 메타파일
         */
        umlFile: null,

        /**
         * ERD
         */
        erdFile: null,
        /**
         * 공통코드
         */
        cdmFile: null,
        cddFile: null,
    }),

    components: {
        UserList
    },

    mounted() {
        admin
            .getL1List()
            .then((response) => {
                if (response.data.returnCode == true) {
                    this.l1List = response.data.result;
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch((err) => {
                errorBox(err);
            });
    },

    computed: {
        selectedL1: {
            get: function () {
                return null;
            },
            set: function (value) {
                this.targetUUID = value.uuid;
            },
        },
    },

    methods: {
        /**
         * 공통코드 입력
         */
        importComCode() {
            if (this.cdmFile == null) {
                messageBox("코드기본 파일을 선택하세요");
                return;
            }

            if (this.cddFile == null) {
                messageBox("코드명세 파일을 선택하세요");
                return;
            }

            admin.importCommonCode(this.cdmFile, this.cddFile)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        messageBox("입력이 완료되었습니다.");
                        this.cdmFile = null;
                        this.cddFile = null;
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * UML 다이어그램
         */
        importUML() {
            if (this.umlFile == null) {
                messageBox("UML 메타파일을 선택하세요");
                return;
            }

            admin.importUML(this.umlFile)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        messageBox("입력이 완료되었습니다.");
                        this.umlFile = null;
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * 지정된 컴포넌트에 문서를 등록한다.
         */
        saveDocument() {
            if (this.targetUUID.length == 0) {
                messageBox("대상 컴포넌트를 선택하세요");
                return;
            }

            if (this.documentModelName.length == 0) {
                messageBox("모델명을 입력하세요");
                return;
            }

            if (this.docFile == null) {
                messageBox("문서파일을 추가하세요");
                return;
            }

            /**
             * 서버 호출
             */
            admin
                .saveDocument(
                    this.documentModelName,
                    this.targetUUID,
                    this.docFile
                )
                .then((response) => {
                    if (response.data.returnCode == true) {
                        messageBox("저장이 완료되었습니다.");
                        this.docFile = null;
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * 모델 반입
         */
        importModel() {
            if (this.importModelName.length == 0) {
                messageBox("모델명을 입력하세요");
                return;
            }

            if (this.file == null) {
                messageBox("모델파일을 추가하세요");
                return;
            }

            admin
                .importModel(this.importModelName, this.file)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        messageBox("반입이 완료되었습니다.");
                        this.file = null;
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * 모델 반출
         */
        exportModel() {
            if (this.exportModelName.length == 0) {
                messageBox("모델명을 입력하세요");
                return;
            }

            admin
                .exportModel(this.exportModelName)
                .then((response) => {
                    // console.log("@.@ exportModel => ", response);
                    FileDownload(response.data, "model.xml");
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * ERD 관계 파일 반입
         */
        importERD() {
            if (this.erdFile == null) {
                messageBox("ERD 관계파일을 선택하세요");
                return;
            }

            admin.importERD(this.erdFile)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        messageBox("입력이 완료되었습니다.");
                        this.file = null;
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        }
    },
};
</script>
