<template>
    <div>
        <v-dialog
            v-model="dialog"
            hide-overlay
            fullscreen
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>피트백</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="addFeedback">등록</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                            <v-data-table
                                    :headers="headers"
                                    :items="feedbacks"
                                    class="elevation-1"
                                    @click:row="handleClick"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="detailDialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn icon dark @click="detailDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{dialogTitle}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                                >
                                    <v-text-field
                                        v-model="reqUser"
                                        :counter="10"
                                        :rules="reqUserRules"
                                        label="이름"
                                        required
                                        :disabled="!flagNew"
                                    ></v-text-field>

                                    <v-textarea
                                        v-model="content"
                                        :rules="contentRules"
                                        label="내용"
                                        required
                                        :disabled="!flagNew"
                                    ></v-textarea>

                                    <v-text-field
                                        v-if="flagAdmin"
                                        v-model="memo"
                                        :rules="memoRules"
                                        label="메모"
                                        required
                                    ></v-text-field>

                                    <v-btn
                                        v-if="flagNew || flagAdmin"
                                        :disabled="checkSaveButton"
                                        color="success"
                                        class="mr-4"
                                        @click="saveFeedback"
                                    >저장
                                    </v-btn>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as feedback from "../api/feedback";
import { errorBox } from "../utils/toast";
import { toDateFormat } from "../utils/utils";
import { mapGetters } from "vuex";
export default {
    
    data() {
        return {
            dialog: false,
            detailDialog: false,

            headers: [
                {
                    text: "요청일",
                    align: "start",
                    value: "displayDate",
                },
                {
                    text: "요청자",
                    value: "requestUser",
                },                
                {
                    text: "적용일",
                    value: "applyDate",
                },                
                {
                    text: "적용메모",
                    value: "applyComment",
                },                
            ],

            feedbacks: [],
            
            flagNew: false,
            valid: false,
            
            reqUser: "",
            reqUserRules: [
                v => !!v || 'required',
                v => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],

            content: "",
            contentRules: [
                v => !!v || 'required',
            ],

            memo: "",
            memoRules: [
                v => !!v || 'required',
            ],

            flagAdmin: false,

            selectedItem: null,
        };
    },

    computed: {
        ...mapGetters(["isAdmin"]),

        dialogTitle() {
            return this.flagNew ? "등록" : (this.flagAdmin ? "적용" : "열람");
        },
        
        contentTitle() {
            return this.flagNew ? "내용" : "메모";
        },

        checkSaveButton() {
            if(this.flagNew == true && this.valid == false)
                return true;
            if(this.flagAdmin == true && this.valid == false)
                return true;

            return false;
        }
    },

    methods: {
        /**
         * requestContents
         */
        showFeedback() {
            this.dialog = true;
            feedback.getFeedbackList()
                .then((response) => {
                    if (response.data.returnCode == true) {
                        this.feedbacks = response.data.result.map(m => {
                            return {
                                displayDate: toDateFormat(m.requestDate),
                                requestDate: m.requestDate,
                                requestUser: m.requestUser,
                                requestContents: m.requestContents,
                                applyDate: toDateFormat(m.applyDate),
                                applyComment: m.applyComment
                            }
                        });
                        this.dialog = true;

                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },

        /**
         * 
         */
        handleClick(item) {
            this.detailDialog = true;
            this.$nextTick(() => {
                this.$refs.form.resetValidation();
            });

            this.selectedItem = item;

            if((item.applyComment == null || item.applyComment.length == 0) && this.isAdmin)
                this.flagAdmin = true;
            else
                this.flagAdmin = false;
            
            this.reqUser = item.requestUser;
            this.content = item.requestContents;
            this.flagNew = false;
        },

        /**
         * 피드백 등록
         */
        addFeedback() {
            this.flagAdmin = false;
            this.flagNew = true;
            this.detailDialog = true;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },

        /**
         * 입력된 피드백을 저장한다.
         */
        saveFeedback() {
            if(this.$refs.form.validate()) {
                if(this.flagNew) {
                    let payload = {
                        requestUser: this.reqUser,
                        requestContents: this.content
                    };

                    feedback.addFeedback(payload)
                        .then((response) => {
                            if (response.data.returnCode == true) {
                                let newFd = response.data.result;
                                let data = {
                                    displayDate: toDateFormat(newFd.requestDate),
                                    requestDate: newFd.requestDate,
                                    requestUser: newFd.requestUser,
                                    requestContents: newFd.requestContents,
                                    applyDate: toDateFormat(newFd.applyDate),
                                    applyComment: newFd.applyComment
                                }

                                this.feedbacks.push(data);
                                this.detailDialog = false;
                            } else {
                                errorBox(response.data.returnMessage);
                            }
                        })
                        .catch((err) => {
                            errorBox(err);
                        });
                }
                else if (this.flagAdmin ) {
                    let payload = {
                        requestDate: this.selectedItem.requestDate,
                        requestUser: this.selectedItem.requestUser,
                        applyComment: this.memo
                    };

                    feedback.updateFeedback(payload)
                        .then((response) => {
                            if (response.data.returnCode == true) {
                                let newFd = response.data.result;
                                this.selectedItem.applyDate = toDateFormat(newFd.applyDate),
                                this.selectedItem.applyComment = this.memo;
                                this.detailDialog = false;
                                this.memo = "";
                            } else {
                                errorBox(response.data.returnMessage);
                            }
                        })
                        .catch((err) => {
                            errorBox(err);
                        });                    
                }
            }
        }
    },
};
</script>
