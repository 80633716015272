<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>자바 소스 검색</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            outlined
                            :disabled="loading"
                            @click="selectFunction"
                            >기능 전체선택</v-btn
                        >
                        <v-btn
                            class="ml-1"
                            dark
                            text
                            :disabled="loading"
                            @click="selectSource"
                            >선택</v-btn
                        >
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="2" md="2">
                                <v-combobox
                                    outlined
                                    :items="domains"
                                    :item-text="(m) => m.domainName"
                                    v-model="selectedDomain"
                                    label="컴포넌트"
                                    ref="comboComp"
                                >
                                    <!-- <template v-slot:item="{ index, item }">
                                        <v-text-field
                                            v-model="item.domainName"
                                            autofocus
                                            flat
                                            background-color="transparent"
                                            hide-details
                                            solo
                                            readonly
                                        ></v-text-field>
                                    </template> -->
                                </v-combobox>
                            </v-col>
                            <v-col cols="2" md="2">
                                <v-combobox
                                    outlined
                                    :items="functions"
                                    :item-text="(m) => m"
                                    v-model="selectedFunc"
                                    label="기능"
                                    ref="comboFunc"
                                >
                                    <!-- <template v-slot:item="{ index, item }">
                                        <v-text-field
                                            v-text="item"
                                            autofocus
                                            flat
                                            background-color="transparent"
                                            hide-details
                                            solo
                                            readonly
                                        ></v-text-field>
                                    </template> -->
                                </v-combobox>
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-text-field
                                    v-model="fileName"
                                    label="파일명"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="3" md="3">
                                <v-text-field
                                    v-model="docString"
                                    label="JavaDoc 문자열"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="2" md="2">
                                <v-btn
                                    class="mt-1"
                                    color="primary"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="search"
                                >
                                    검색
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12">
                                <v-data-table
                                    v-model="selected"
                                    :headers="headers"
                                    :items="javaSources"
                                    :single-select="true"
                                    item-key="id"
                                    show-select
                                    class="elevation-1"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { messageBox, errorBox } from "../utils/toast";
import * as rdf from "../api/rdf";
import DOMAIN from "../assets/domain.json";

export default {

    computed: {
        selectedDomain: {
            get: function() {
                return this.selectedComponent;
            },

            set: function(value) {
                // console.log("####### 1 ", value, this.$refs.comboFunc);
                this.$nextTick(() => {
                    this.$refs.comboFunc.internalSearch = "";
                    if ( value != null && typeof(value) === "object" ) {
                        this.componentName = value.componentName;
                        this.functions = value.funs;
                        this.selectedComponent = value;
                    }
                    else {
                        this.componentName = "";
                        this.functions = [];
                    }
                });
            }
        },

        selectedFunc: {
            get: function() {
                return null;
            },

            set: function(value) {
                // console.log("####### 2 ", value);
                this.$nextTick(() => {
                    if ( value != null && value.length > 0 ) {
                        this.funcName = value;
                    }
                });
            }
        }
    },

    data() {
        return {
            /**
             * 업무 도메인
             */
            domains: DOMAIN.domains,

            selectedComponent: null,

            functions: [],

            headers: [
                {
                    text: "소스파일",
                    align: "start",
                    value: "subject",
                },
                {
                    text: "관계",
                    value: "predicate",
                },
                {
                    text: "내용",
                    value: "object",
                },
            ],

            /**
             * 자바 소스 목록
             */
            javaSources: [],

            /**
             * 검색 입력값
             */
            funcName: "",
            fileName: "",
            docString: "",

            selected: [],
            loading: false,

            dialog: false,
        };
    },

    methods: {
        /**
         * 자바소스 검색을 시작한다.
         */
        beginSearch() {
            this.fileName = "";
            this.docString = "";
            this.javaSources = [];
            this.selected = [];
            this.funcName = "";
            this.dialog = true;
            this.$nextTick(() => {
                this.$refs.comboComp.reset();
                this.$refs.comboFunc.reset();
                // this.$refs.comboComp.internalSearch = "";
                // this.$refs.comboFunc.internalSearch = "";
            });
        },

        /**
         * 선택 버튼 클릭
         */
        selectSource() {
            this.$emit("selectEvent", this.selected, this.funcName, "src");
            this.dialog = false;
        },

        /**
         * 업무기능 전체 추가
         */
        selectFunction() {
            this.$emit("selectEvent", this.funcName, this.funcName, "fn");
            this.dialog = false;
        },

        /**
         * 자바 소스 검색
         */
        search() {
            if (this.funcName === undefined || this.funcName.length == 0) {
                messageBox("기능을 선택 하세요");
                return;
            }

            if (this.fileName.length == 0 && this.docString.length == 0) {
                messageBox("'파일명' 또는 'JavaDoc 문자열'을 입력 하세요");
                return;
            }

            this.loading = true;
            this.selected = [];

            rdf.searchSource(this.funcName, this.fileName, this.docString).then((response) => {
                //console.log("@.@ search RESULT:", response);
                this.loading = false;

                if (response.data.returnCode == true) {
                    let id = 1;
                    this.javaSources = response.data.result.map((m) => {
                        return {
                            orgSubject: m.subject,
                            subject: m.subject.split(".").slice(-1)[0],
                            predicate: m.predicate,
                            object:
                                m.object.length > 80
                                    ? m.object.substring(0, 80) + "..."
                                    : m.object,
                            fullObject: m.object,
                            rank: m.rank,
                            id: id++,
                        };
                    });
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },
    },
};
</script>

<style></style>
