import net from "@/utils/net.js";

/**
 * 
 * @param {*} payload 
 * @returns 
 */
export function updateFeedback(payload) {
	return net.put(`/v1/feedbacks`, payload);
}

/**
 * 
 * @param {*} payload 
 * @returns 
 */
export function addFeedback(payload) {
	return net.post(`/v1/feedbacks`, payload);
}

/**
 * 
 * @param {*} requestDate 
 * @param {*} requestUser 
 * @returns 
 */
export function getFeedback(requestDate, requestUser) {
	return net.get(`/v1/feedbacks/${requestDate}/${requestUser}`);
}


/**
 * 
 * @returns 
 */
export function getFeedbackList() {
	return net.get(`/v1/feedbacks`);
}
