<template>
    <v-dialog
        transition="dialog-top-transition"
        max-width="800"
        v-model="dialog"
    >
        <v-card>
            <v-toolbar color="#757575" dense dark>Java Doc</v-toolbar>
            <v-card-text class="mt-5">
                <div v-html="javaDoc" />
            </v-card-text>
        </v-card>

        <!-- <div class="preview">
            <p v-html="javaDoc" />
        </div> -->
    </v-dialog>
</template>

<script>
export default {
    data: () => {
        return {
            javaDoc: "",
            dialog: false,
        };
    },

    methods: {
        showHtml(doc) {
            this.javaDoc = doc;
            this.dialog = true;
        },
    },
};
</script>

<style lang="scss" scoped>
// .preview {
//     background: #d3d3d30f;
//     padding: 10px;
//     border-radius: 10px;
//     width: 100%;
//     color: rgba(255, 255, 255, 0.8);
//     display: flex;
//     flex-direction: column;
// }

div > ::v-deep .en {
    background:rgb(243, 243, 243);
    color: blue;
    margin-bottom: 5px;
    width: 100%;
}

div > ::v-deep .ko {
    background:rgb(243, 243, 243);
    color: blue;
    margin-bottom: 8px;
}

::v-deep pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
</style>
