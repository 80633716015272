<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="3" md="3">
                            <v-text-field
                                v-model="tableName"
                                label="테이블 이름"
                                @keyup.enter="search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" md="3">
                            <v-text-field
                                v-model="tableId"
                                label="테이블 ID"
                                @keyup.enter="search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" md="2">
                            <v-btn class="mt-1" color="primary" @click="search">
                                검색
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="6">
                            <v-list dense height="450px" class="elevation-1">
                                <v-subheader
                                    ><span style="color: blue"
                                        >검색 테이블</span
                                    ></v-subheader
                                >
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in searchResult"
                                        :key="i"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="item.tableName"
                                            ></v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                            <v-icon @click="addTable(item)"
                                                >mdi-plus</v-icon
                                            >
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="6">
                            <v-list
                                dense
                                max-height="450px"
                                class="elevation-1"
                            >
                                <v-subheader
                                    ><span style="color: blue"
                                        >선택 테이블</span
                                    ></v-subheader
                                >
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        v-for="(item, i) in selectTable"
                                        :key="i"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="item.tableName"
                                            ></v-list-item-title>
                                            <v-list-item-subtitle>
                                                <v-row>
                                                    <v-checkbox class="ml-4" label="SELECT" v-model="item.select"></v-checkbox>
                                                    <v-checkbox class="ml-2" label="INSERT" v-model="item.insert"></v-checkbox>
                                                    <v-checkbox class="ml-2" label="UPDATE" v-model="item.update"></v-checkbox>
                                                    <v-checkbox class="ml-2" label="DELETE" v-model="item.delete"></v-checkbox>
                                                </v-row>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                            <v-icon @click="deleteTable(item)"
                                                >mdi-delete</v-icon
                                            >
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import * as tab from "../api/table";
import { /*messageBox,*/ errorBox } from "../utils/toast";

export default {
    components: {},

    data() {
        return {
            tableId: "",
            tableName: "",
            searchResult: [],
            selectTable: [],
        };
    },

    methods: {
        /**
         * 선택된 테이블을 문자열로 반환한다.
         */
        getSelectedtables() {
            return this.selectTable.map((m) => {
                return m.tableName.concat("|", (m.insert == true ? "C" : "_"),
                            (m.select == true ? "R" : "_"), (m.update == true ? "U" : "_"), (m.delete == true ? "D" : "_"));
            }).join(",");
        },

        /**
         * 선택된 테이블을 조회한다.
         */
        setSelectedTable(tables) {
            this.tableName = "";
            this.tableId = "";
            this.searchResult = [];
            this.selectTable = [];
            
            if(tables != null && tables.length > 0) {
                tab.getTable(tables).then((response) => {
                    if (response.data.returnCode == true) {
                        this.selectTable = response.data.result;
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch(err => {
                    errorBox(err);
                });
            }
        },

        /**
         *
         */
        search() {
            tab.searchTable(this.tableId, this.tableName).then((response) => {
                if (response.data.returnCode == true) {
                    this.searchResult = response.data.result;
                } else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        },

        /**
         *
         */
        addTable(tab) {
            //console.log("@.@ addTable:", tab);
            let index = this.selectTable.findIndex(
                (m) => m.tableId === tab.tableId
            );
            if (index == -1) {
                let locale = process.env.VUE_APP_I18N_LOCALE || 'ko';
                let tabObj = {
                    select: false,
                    insert: false,
                    update: false,
                    delete: false,
                    tableId: tab.tableId,
                    tableName: locale === 'ko' ? tab.tableName : tab.tableId
                };
                this.selectTable.push(tabObj);
                this.$emit("changedEvent"); // 수정됨을 통보
            }
        },

        /**
         *
         */
        deleteTable(tab) {
            // console.log("@.@ deleteTable:", tab);
            let index = this.selectTable.indexOf(tab);
            if (index != -1) {
                this.selectTable.splice(index, 1);
                this.$emit("changedEvent"); // 수정됨을 통보
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-list-item-group {
    height: 390px;
    overflow-y: auto;
}
</style>
>
