import net from "@/utils/net.js";


/**
 * RDF 클래스 목록을 조회한다.
 * 
 * @returns RDF 클래스 목록
 */
export function getClasses() {
	return net.get(`/v1/classes`);
}

/**
 * 지정된 이름의 클래스 정보를 가져온다.
 * 
 * @param {*} className 
 * @returns 
 */
export function getClass(className) {
	return net.get(`/v1/classes/${className}`);
}

/**
 * RDF 클래스를 삭제한다.
 * @param {*} className 삭제할 클래스 이름
 * @returns 
 */
export function deleteClass(className) {
	return net.delete(`/v1/classes/${className}`);
}

/**
 * 지정된 RDF 클래스를 등록한다.
 * @param {*} classObject 
 * @returns 
 */
export function saveClass(classObject) {
	return net.post(`/v1/classes`, classObject);
}
