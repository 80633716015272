import net from "@/utils/net.js";

/**
 * 공통코드 데이터 반입
 * @param {*} cdmFile 
 * @param {*} cddFile 
 * @returns 
 */
export function importCommonCode(cdmFile, cddFile) {
	var fd = new FormData();
    fd.append('fileCdM', cdmFile);
    fd.append('fileCdD', cddFile);

	return net.post(`/v1/tables/code`, fd, {
		timeout: 120000,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

/**
 * ERD 관계 파일 입력
 * @param {*} file 
 * @returns 
 */
export function importERD(file) {
	var fd = new FormData();
    fd.append('file', file);

	return net.post(`/v1/tables/relations`, fd, {
		timeout: 300000,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}


/**
 * UML 메타정보를 입력한다.
 * @param {*} file 
 * @returns 
 */
export function importUML(file) {
	var fd = new FormData();
    fd.append('file', file);

	return net.post(`/v1/uml`, fd, {
		timeout: 120000,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

/**
 * 
 * @param {*} modelName 
 * @param {*} uuid 
 * @param {*} file 
 * @returns 
 */
export function saveDocument(modelName, uuid, file) {
	var fd = new FormData();
    fd.append('file', file);

	return net.post(`/v1/docs/${modelName}/${uuid}`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

/**
 * L1 리스트를 조회한다.
 * @returns 
 */
export function getL1List() {
	return net.get(`/v1/docs/l1`);
}


/**
 * 모델 다운로드
 * @param {*} modelName 
 * @returns 
 */
export function exportModel(modelName) {
	return net.get(`/v1/export/${modelName}`, {responseType: 'blob'});
}

/**
 * 모델 업로드
 * @param {*} modelName 
 * @param {*} file 
 * @returns 
 */
export function importModel(modelName, file) {
	var fd = new FormData();
    fd.append('file', file);

	return net.post(`/v1/import/${modelName}`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}
