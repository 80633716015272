<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>접속이력</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <!-- <v-btn
                        dark
                        text
                        @click="dialog = false"
                        >닫기</v-btn
                    > -->
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <GChart type="ColumnChart" :data="accessData" :options="chartOptions" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as users from "../api/users";
import { errorBox } from "../utils/toast";
import { GChart } from "vue-google-charts";

export default {
    components: {
        GChart
    },

    data() {
        return {
            accessData: [
                ["NO DATA", "NO DATA"],
                ["NO DATA", 0],
            ],
            chartOptions: {
                chart: {
                },
                height: 400,
                curveType: 'function',
                pointSize: 5,
                legend: { position: "top", alignment: "center", textStyle: { color: "black" } },
            },
            
            dialog: false,
        };
    },

    methods: {
        /**
         * 변경이력을 조회한다.
         */
        showAccessChart() {
            users.getLogs()
                .then((response) => {
                    if (response.data.returnCode == true) {
                        let data = [];
                        data.push(["일자", "접속수"])
                        response.data.result.forEach(e => {
                            data.push([e.accessDate, e.accessCount]);
                        });
                        this.accessData = data;
                        this.dialog = true;
                    } else {
                        errorBox(response.data.returnMessage);
                    }
                })
                .catch((err) => {
                    errorBox(err);
                });
        },
    },
};
</script>

<style></style>
