<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dense dark color="light-blue darken-4">
                <v-btn icon dark @click="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>상품조건 검색 (실험적 단계)</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <!-- <v-btn dark text @click="cancel">닫기</v-btn> -->
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-row dense>
                        <v-col cols="5">
                            <v-text-field
                                v-model="inputText"
                                label="검색할 문장을 입력하세요"
                                type="text"
                                outlined
                                hide-details="auto"
                                class="mt-2"
                                @keyup.enter="search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                            <v-btn
                                class="mt-4 white--text"
                                color="light-blue darken-4"
                                @click="search"
                                :loading="loading"
                                >조건 검색</v-btn
                            >
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="tokens"
                                label="형태소"
                                class="mt-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="searchResult"
                                :items-per-page="5"
                                class="elevation-1"
                            >
                                <template v-slot:item.best="{ item }">
                                    <v-chip v-if="item.best.length > 0 && item.best !== '0'"
                                        color="orange"
                                        text-color="white"
                                        dark
                                    >
                                        {{item.best}}
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import * as nlp from "../api/nlp";
import { errorBox, messageBox } from "../utils/toast";

export default {
    data() {
        return {
            /**
             * 다이얼로그 제어 변수
             */
            dialog: false,
            loading: false,

            inputText: "",
            searchResult: [],
            tokens: "",
            headers: [
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "best",
                    width: "1%",
                },
                { text: "조건코드", value: "category", width: "10%", },
                { text: "조건코드명", value: "categoryName", width: "20%",  },
                { text: "설명", value: "text" },
            ],
        };
    },

    methods: {
        /**
         * 창 열기
         */
        show() {
            this.dialog = true;
        },

        /**
         * 창 닫기
         */
        cancel() {
            this.$nextTick(() => {
                this.dialog = false;
            });
        },

        /**
         * 상품계약조건을 검색한다.
         */
        search() {
            if (this.inputText.length == 0) {
                messageBox("검색할 문장을 입력하세요");
                return;
            }

            /**
             * 서버 호출
             */
            let data = {
                inputText: this.inputText,
            };

            this.loading = true;
            nlp.searchCondition(data)
                .then((response) => {
                    if (response.data.returnCode == true) {
                        console.log(response.data.result);
                        this.tokens = response.data.result.tokens;
                        this.searchResult = response.data.result.conditions.map(
                            (e, index) => {
                                let txt = e.raw.text.reduce((prev, current) =>
                                    prev.length > current.length
                                        ? prev
                                        : current
                                );
                                return {
                                    best: index == 0 ? "BEST" : "",
                                    category: e.category,
                                    categoryName: e.raw.categoryName,
                                    text:
                                        txt.length <= 90
                                            ? txt
                                            : txt.substring(0, 90) + "...",
                                };
                            }
                        );
                    } else {
                        messageBox(response.data.returnMessage);
                    }
                    this.loading = false;
                })
                .catch((err) => {
                    errorBox(err);
                    this.loading = false;
                });
        },
    },
};
</script>
