<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>자바 API 검색</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            :disabled="selected.length == 0" 
                            @click="selectAPI">선택</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="2" md="2">
                                <v-combobox
                                    outlined
                                    :items="domains"
                                    :item-text="(m) => m.domainName"
                                    v-model="selectedDomain"
                                    label="컴포넌트"
                                    ref="comboComp"
                                >
                                </v-combobox>
                            </v-col>
                            <v-col cols="2" md="2">
                                <v-combobox
                                    outlined
                                    :items="functions"
                                    :item-text="(m) => m"
                                    v-model="selectedFunc"
                                    label="기능"
                                    ref="comboFunc"
                                >
                                </v-combobox>
                            </v-col>
                            
                            <v-col cols="2" md="2">
                                <v-combobox
                                    outlined
                                    :items="searchType"
                                    :item-text="(m) => m.label"
                                    v-model="selectedType"
                                    label="검색대상"
                                    ref="comboType"
                                >
                                </v-combobox>
                            </v-col>
                            <v-col cols="2" md="2">
                                <v-text-field
                                    v-model="fileName"
                                    :label="searchTypeLabel"
                                    @keyup.enter="search"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" md="2">
                                <v-text-field
                                    v-model="docString"
                                    label="JavaDoc 문자열"
                                    @keyup.enter="search"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" md="2">
                                <v-btn 
                                    class="mt-1" 
                                    color="primary"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="search">
                                    검색
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12">
                            <v-data-table
                                v-model="selected"
                                :headers="headers"
                                :items="searchResult"
                                :single-select="true"
                                item-key="id"
                                show-select
                                class="elevation-1"
                            >
                                <template v-slot:item.rank="{ item }">
                                    <v-chip v-if="item.rank.length > 0 && item.rank !== '0'"
                                        :color="rankColor(item.rank)"
                                        dark
                                    >
                                        {{ item.rank }}
                                    </v-chip>
                                </template>
                            </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="justify-start ml-4 mt-0 pt-0">
                    <v-btn
                        text
                        outlined
                        :disabled="selected.length == 0" 
                        @click="selectAPI">선택
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {messageBox, errorBox} from "../utils/toast";
import * as rdf from "../api/rdf";
import DOMAIN from "../assets/domain.json";

export default {
    computed: {
        selectedDomain: {
            get: function() {
                return this.selectedComponent == null ? "" : this.selectedComponent; // ALL
            },
            set: function(value) {
                //console.log("####### 1 ", value, this.$refs.comboFunc);
                this.$nextTick(() => {
                    this.$refs.comboFunc.internalSearch = "";
                    if ( value != null && typeof(value) === "object" ) {
                        this.componentName = value.componentName;
                        this.functions = value.funs;
                        this.selectedComponent = value;
                        if(value.funs.length == 1) {
                            this.$refs.comboFunc.internalSearch = value.funs[0];
                            this.funcName = value.funs[0];
                        }
                    }
                    else {
                        this.componentName = "";
                        this.functions = [];
                    }
                });
            }
        },
        selectedFunc: {
            get: function() {
                return ""; // ALL
            },
            set: function(value) {
                // console.log("####### 2 ", value);
                this.$nextTick(() => {
                    if ( value != null && value.length > 0 ) {
                        this.funcName = value;
                    }
                });
            }
        },

        selectedType: {
            get: function() {
                return {
                    code: "C",
                    label: "클래스"
                };
            },

            set: function(value) {
                this.$nextTick(() => {
                    if ( value != null && typeof(value) === "object" ) {
                        this.selectedSearchType = value.code;
                        if ( value.code === 'C')
                            this.searchTypeLabel = "클래스 이름";
                        else
                            this.searchTypeLabel = "메소드 이름";
                    }
                });
            }
        },

        headers() {
            if(this.selectedSearchType === 'C')
                return this.classHeaders;
            else
                return this.methodHeaders;
        }
    },

    data() {
        return {
            /**
             * 검색구분
             */
            searchType: [
                {
                    code: "C",
                    label: "클래스"
                },
                {
                    code: "M",
                    label: "메소드"
                }
            ],
            selectedSearchType: "C",
            searchTypeLabel: "클래스 이름",

            selectedComponent: null,

            domains: DOMAIN.domains,

            functions: [],

            classHeaders: [
                {
                    text: "클래스",
                    align: "start",
                    value: "className",
                },
                {
                    text: "JavaDoc",
                    value: "javaDoc",
                },                
            ],
            methodHeaders: [
                {
                    text: "클래스",
                    align: "start",
                    value: "className",
                },
                {
                    text: "메소드",
                    value: "methodName",
                },
                {
                    text: "호출수",
                    value: "referCounter",
                },
                {
                    text: "중요도",
                    value: "rank",
                },
                {
                    text: "JavaDoc",
                    value: "javaDoc",
                },                
            ],

            /**
             * 자바 소스 목록
             */
            searchResult: [],

            /**
             * 검색 입력값
             */
            funcName: "전체",
            fileName: "",
            docString: "",

            selected: [],
            loading: false,

            dialog: false,
        };
    },

    methods: {
        rankColor(rank) {
            if ( rank == null || rank.length == 0 ) {
                return "#FFFFFF";
            }
            else {
                let r = parseInt(rank);
                switch(r) {
                    case 5: return "#FF7043";
                    case 4: return "#FF8A65";
                    case 3: return "#FFAB91";
                    case 2: return "#FFCCBC";
                    case 1: return "#FBE9E7";
                    default: return "#FFFFFF";
                }
            }
        },

        /**
         * 자바소스 검색을 시작한다.
         */
        beginSearch() {
            // this.fileName = "";
            // this.docString = "";
            // this.searchResult = [];
            // this.selected = [];
            this.dialog = true;
        },

        /**
         * 선택 버튼 클릭
         */
        selectAPI() {
            this.$emit("selectEvent", this.selected);
            this.dialog = false;
        },

        /**
         * 자바 소스 검색
         */
        search() {
            if (this.funcName === undefined || this.funcName.length == 0) {
                messageBox("기능을 선택 하세요");
                return;
            }

            // if ( this.fileName.length == 0 && this.docString.length == 0 ) {
            //     messageBox("'클래스/메소드' 또는 'JavaDoc 문자열'을 입력하세요");
            //     return;
            // }

            this.loading = true;
            this.selected = [];

            rdf.searchApi(this.funcName, this.selectedSearchType, this.fileName, this.docString).then(response => {
                //console.log("@.@ API search RESULT:", response);
                this.loading = false;

                if ( response.data.returnCode == true ) {
                    let id = 1;
                    this.searchResult = response.data.result.map(m => {
                        return {
                            subject: m.subjectUri,
                            className: m.className,
                            methodName: m.methodName,
                            rank: m.rank,
                            referCounter: m.referCounter,
                            javaDoc:  m.javaDoc.length > 80
                                    ? m.javaDoc.substring(0, 80) + "..."
                                    : m.javaDoc,
                            id: id++
                        }
                    });
                }
                else {
                    errorBox(response.data.returnMessage);
                }
            })
            .catch(err => {
                errorBox(err);
            });
        }
    },
};
</script>

<style></style>
