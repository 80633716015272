<template>
    <v-container fluid>
        <v-row>
            <v-col cols="2">
                <v-card class="mx-auto" tile height="auto">
                    <v-list dense @change="selectTable">
                        <v-subheader
                            ><span style="color: blue"
                                >테이블</span
                            ></v-subheader
                        >
                        <v-list-item-group color="primary">
                            <v-list-item
                                v-for="(item, i) in tables"
                                :key="i"
                                @change="selectTable(item)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="item.tableName"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="10">
                <v-row>
                    <v-col cols="2">
                        <v-checkbox
                            v-model="select"
                            label="SELECT"
                            hide-details="auto"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="2">
                        <v-checkbox
                            v-model="insert"
                            label="INSERT"
                            hide-details="auto"
                        ></v-checkbox>
                    </v-col>
                     <v-col cols="2">
                         <v-checkbox
                            v-model="update"
                            label="UPDATE"
                            hide-details="auto"
                        ></v-checkbox>
                    </v-col>
                     <v-col cols="2">
                         <v-checkbox
                            v-model="delete2"
                            label="DELETE"
                            hide-details="auto"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4" class="mt-3">
                        <span class="text-h6">{{tableId}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="searchResult"
                            item-key="id"
                            class="elevation-1 mt-0 pt-0"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            dialog: false,

            tables: [],

            headers: [
                {
                    text: "컬럼명",
                    align: "start",
                    value: "columnName",
                },
                {
                    text: "컬럼ID",
                    value: "columnId",
                },
                {
                    text: "타입",
                    value: "dataType",
                },
                {
                    text: "PK",
                    value: "pk",
                },
                {
                    text: "주석",
                    value: "commentkr",
                },
            ],
            searchResult: [],
            
            tableId: "",
            select: false,
            insert: false,
            update: false,
            delete2: false
        };
    },

    methods: {
        /**
         * 
         */
        showTable(data) {
            this.searchResult = [];
            this.tables = data;

            this.select = false;
            this.insert = false;
            this.update = false;
            this.delete2 = false;
            this.tableId = "";
        },

        /**
         * 
         */
        selectTable(e) {
            this.searchResult = e.columns;

            this.select = e.select;
            this.insert = e.insert;
            this.update = e.update;
            this.delete2 = e.delete;
            this.tableId = e.tableId;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-list-item-group {
    height: 531px;
    overflow-y: auto;
}
</style>
>
