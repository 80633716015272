<template>
    <v-row justify="center">
        <v-dialog
            max-width="400px"
            v-model="dialog"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>문서 선택</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            :disabled="this.selectedItem == -1"
                            @click="selectedTopic"
                            >선택</v-btn
                        >
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid  class="mt-3">
                        <v-row dense>
                            <v-col cols="12">
                                <v-list dense>
                                    <v-list-item-group
                                        v-model="selectedItem"
                                        color="primary"
                                    >
                                        <v-list-item
                                            v-for="(item, i) in topicList"
                                            :key="i"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-text="item.value"
                                                ></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            selectedItem: -1,
            topicList: [],

            dialog: false,
        };
    },

    methods: {
        /**
         * 자바소스 검색을 시작한다.
         */
        beginSearch(topics) {
            this.selectedItem = -1;
            this.topicList = topics;
            this.dialog = true;
        },

        /**
         * 선택 버튼 클릭
         */
        selectedTopic() {
            if ( this.selectedItem != -1 ) {
                this.$emit("selectTopicEvent", this.topicList[this.selectedItem]);
                this.dialog = false;
            }
        },
    },
};
</script>

<style></style>
