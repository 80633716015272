<template>
    <v-dialog
        v-model="dialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
    >
        <v-btn
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="dialog = false"
            class="mr-7"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card>
            <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>문서보기</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <!-- <v-btn dark text @click="dialog = false">닫기</v-btn> -->
                </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12">
                            <vue-friendly-iframe
                                :src="sourceUrl"
                                crossorigin="*"
                            ></vue-friendly-iframe>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            sourceUrl: "",
        };
    },

    methods: {
        showDocument() {
            this.dialog = true;
            // this.$nextTick(() => {
            //     this.$refs.toastuiViewer.invoke("setMarkdown", markdown);
            // });
        },
    },
};
</script>

<style></style>
